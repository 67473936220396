$share-size: 5rem;

.share-page {
    z-index: $zindex-navigation + 1;
}

.share-container {
    > .share-links {
        padding: $spacer-small;
        background: $egg-shell;
        box-shadow: $shadow-2;
        border-radius: $border-radius-2;

        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: $share-size;
        grid-column-gap: $spacer-small;

        width: fit-content;

        > * {
            @extend %flex-center;
            border-radius: $border-radius-1;
            height: $share-size;
            width: $share-size;
            box-shadow: $shadow-1;
            color: $white;
            @include font-awesome(h3);

            &:hover {
                color: $egg-shell;
            }

            svg {
                vertical-align: top;
            }
        }

        > .share-default {
            cursor: pointer;
            background: $green-700;
        }

        > .share-facebook {
            background: $color-facebook;
        }

        > .share-reddit {
            background: $color-reddit;
        }

        > .share-twitter {
            background: $color-twitter;
        }

        > .share-linkedin {
            background: $color-linkedin;
        }

        > .share-google {
            background: $color-google;
        }
    }
}
