@media (max-width: $small - 1) {

  /* quick small resolution reset */
  //.mod,
  //.col,
  //fieldset {
  //  display: block !important;
  //  float: none !important;
  //  clear: none !important;
  //  width: auto !important;
  //  margin-left: 0 !important;
  //  margin-right: 0 !important;
  //  border: 0;
  //}

  .flex-container {
    flex-direction: column;
  }
  
  .flex-row {
    flex-direction: row;
  }

  /* layouts for tiny screens */
  .tiny-hidden {
    display: none !important;
  }

  .tiny-visible {
    display: block !important;
  }

  //.tiny-no-float {
  //  float: none;
  //}
  //
  //.tiny-inbl {
  //  display: inline-block;
  //  float: none;
  //  vertical-align: top;
  //}
  //
  ///* widths for tiny screens */
  //.tiny-w25 {
  //  width: 25% !important;
  //}
  //
  //.tiny-w33 {
  //  width: 33.333333% !important;
  //}
  //
  //.tiny-w50 {
  //  width: 50% !important;
  //}
  //
  //.tiny-w66 {
  //  width: 66.666666% !important;
  //}
  //
  //.tiny-w75 {
  //  width: 75% !important;
  //}
  //
  //.tiny-w100,
  //.tiny-wauto {
  //  display: block !important;
  //  float: none !important;
  //  clear: none !important;
  //  width: auto !important;
  //  margin-left: 0 !important;
  //  margin-right: 0 !important;
  //  border: 0;
  //}
  //
  ///* margins for tiny screens */
  //.tiny-man,
  //.tiny-ma0 {
  //  margin: 0 !important;
  //}
  //
  //.tiny-pan,
  //.tiny-pa0 {
  //  padding: 0 !important;
  //}
}
