.map-marker--title {
    @include font-size(base);

    svg {
        margin-right: $spacer-small;
    }
}

.map-marker--desc {
    @include font-size(smaller);
}