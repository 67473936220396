%flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

%tag--round {
    @extend %flex-center;
    border-radius: 50%;
}

.tag--round {
    @extend %tag--round;
}

%btn--round {
    @extend %tag--round;
    cursor: pointer;
}

.btn--round {
    @extend %btn--round;
}

.btn--footer-plus {
    @extend %btn--round;
    @include font-awesome(h3);
    height: 6rem;
    width: 6rem;
    margin: 1rem 0;
}

.btn--flash {
    @extend %btn--round;
    @include font-awesome(h3);
    right: $spacer-medium;
    bottom: $spacer-medium;
    height: 7rem;
    width: 7rem;
}

button, .btn {
    > .faws + span {
        margin-left: $spacer-tiny;
    }
}

.btn--big {
    @include font-size(h4);
    border-radius: $border-radius-2;
    margin: 0 auto;
    padding: $spacer-medium;
}

.btn--menu-icon {
    @extend %flex-center;
    min-height: 5rem;
    min-width: 5rem;
    cursor: pointer;
}

.btn--background {
    background-color: $egg-shell;
}

.btn--login {
    @extend .btn--background;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: $spacer-large;

    > :first-child {
        justify-content: center;
        width: 20%;
    }
}