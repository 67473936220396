.banner {
    background-color: $white;
    border-radius: $border-radius-2;
    color: $gray-600;
    display: block;
    padding: 3rem;
    max-width: $wrapper-medium-plus;
    margin: auto;
    text-align: center;

    > .logo-txt-logo {
        display: flex;
        flex-direction: column;
        align-items: center;

        > div:nth-child(2) {
            margin-top: $spacer-medium;
        }
    }

    img {
        height: 200px;
        width: 200px;
    }
}

.banner .ltl-action {
    color: $gray-500;
}
