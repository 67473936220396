span.code-block {
    background-color: $secondary-600;
    color: $white;
    padding: 0 2rem;
    display: inline-block;
    border-radius: 3px;
    box-shadow: 0px 0px 1px $secondary-600;
}

.code-promo {
    background-color: $white;
    border: $border-2;
    border-radius: $border-radius-2;
    padding: $spacer-medium;

    .code-validity {
        color: $color-base-muted;
        @include font-size(smaller);
    }

    .code-name {
        color: $color-highlight;
    }
}