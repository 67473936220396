.autocomplete {
    position: relative;
}
.autocomplete-result-list {
    background-color: $white;
    border: solid 1px #d9d9d9;
    cursor: pointer;
    left: 0;
    position: absolute;
    right: 0;
    visibility: hidden;
    z-index: 1;
}

.autocomplete-result-list > div > * {
    padding: $spacer-tiny;
}

.autocomplete-result-list > div > a {
    display: block;
    color: $color-base;
}

.autocomplete-result-list > div:hover, .autocomplete-result-list > div.hover {
    background-color: #d9d9d9;
}

.autocomplete-input:focus + .autocomplete-result-list:not(:empty), .autocomplete[autocomplete-show-results=true] .autocomplete-result-list:hover {
    visibility: visible;
}