.loading {
    &::after {
        content: '';

        position: absolute;
        bottom: -$spacer-medium;
        left: -$spacer-medium;
        right: -$spacer-medium;
        top: -$spacer-medium;
        background-color: #eee9;

        z-index: $zindex-loading;

        background-image: url('../../images/spinner.svg');

        background-size: 100px;
        background-repeat: no-repeat;
        background-position: center;

        border-radius: 1rem;
    }
}

body.loading {
    &::after {
        position: fixed;
    }
}
