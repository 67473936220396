// 1.5 is default line-height
$carousel-words-height: 1.5em;

.carousel-words {
    display: flex;

    > .carousel-placeholder {
        display: none; /* shown in html to have something waiting for css load and animation */
    }

    > .carousel-words-list {
        display: block !important; /* hidden in html to avoid complete list show */
        height: $carousel-words-height;
        line-height: $carousel-words-height;
        overflow: hidden;

        > div {
            position: relative;
            overflow: hidden;
        }
    }

    &--scroll-10 {
        > .carousel-words-list {
            > div {
                animation: scroll-10 15s ease-in-out infinite;
            }
        }
    }

    &--scroll-underlined-10 {
        > .carousel-words-list {
            border-bottom: 1px solid;

            > div {
                animation: scroll-10 15s ease-in-out infinite;
            }
        }
    }

    &--highlight {
        > .carousel-words-list {
            > div {
                background-color: $color-highlight-background;
                color: $color-highlight-inverse;

                animation: openclose 5s ease-in-out infinite;

                > div {
                    padding: 0 $spacer-small;
                }
            }
        }
    }
}

@keyframes scroll-10 {
    0% {
        top: 0rem;
    }
    5% {
        top: 0rem;
    }
    10% {
        top: - $carousel-words-height;
    }
    15% {
        top: - $carousel-words-height;
    }
    20% {
        top: - $carousel-words-height * 2;
    }
    25% {
        top: - $carousel-words-height * 2;
    }
    30% {
        top: - $carousel-words-height * 3;
    }
    35% {
        top: - $carousel-words-height * 3;
    }
    40% {
        top: - $carousel-words-height * 4;
    }
    45% {
        top: - $carousel-words-height * 4;
    }
    50% {
        top: - $carousel-words-height * 5;
    }
    55% {
        top: - $carousel-words-height * 5;
    }
    60% {
        top: - $carousel-words-height * 6;
    }
    65% {
        top: - $carousel-words-height * 6;
    }
    70% {
        top: - $carousel-words-height * 7;
    }
    75% {
        top: - $carousel-words-height * 7;
    }
    80% {
        top: - $carousel-words-height * 8;
    }
    85% {
        top: - $carousel-words-height * 8;
    }
    90% {
        top: - $carousel-words-height * 9;
    }
    95% {
        top: - $carousel-words-height * 9;
    }
    100% {
        top: - $carousel-words-height * 10;
    }
}

@keyframes openclose {
    0% {
        top: 0rem;
        width: 0;
    }
    5% {
        width: 0;
    }
    15% {
        width: 100%;
    }
    30% {
        width: 100%;
    }
    33% {
        width: 0;
    }
    35% {
        top: 0rem;
        width: 0;
    }
    38% {
        top: - $carousel-words-height;
        width: 0;
    }
    48% {
        width: 100%;
    }
    62% {
        width: 100%;
    }
    66% {
        top: - $carousel-words-height;
        width: 0;
    }
    71% {
        top: - $carousel-words-height * 2;
        width: 0;
    }
    86% {
        width: 100%;
    }
    95% {
        width: 100%;
    }
    98% {
        top: - $carousel-words-height * 2;
        width: 0;
    }
    100% {
        top: 0;
        width: 0;
    }
}