/* ----------------------------- */
/* ==Buttons                     */
/* ----------------------------- */
/* preferably use <button> for buttons !*/
/* use .btn-- or .button-- classes for variants */

%btn {
  display: inline-block;
  padding: $spacer-small $spacer-small-plus;
  user-select: none;
  transition: 0.25s;
  transition-property: box-shadow, background-color, color, border;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
  color: $color-base;
  border: none;
  border-radius: $border-radius-1;
  background-color: $color-muted;
  font-family: inherit;
  font-size: inherit;
  line-height: 1;

  &:not(.disabled) {
      cursor: pointer;
  }
}

%btn--border {
    background-color: inherit;
    padding: calc(1rem - 2px) $spacer-small-plus;
    border-style: solid;
    border-width: 2px;
    box-shadow: none;
}

%btn--border-light {
    @extend %btn--border;
    padding: calc(1rem - 1px) $spacer-small-plus;
    border-width: 1px;
}

%btn--link {
  background-color: inherit;
  box-shadow: none;
  text-decoration: underline;

  &:not(.disabled):hover {
    text-decoration: none;
  }
}

%btn--ghost {
    background-color: inherit;
    box-shadow: none;
}

.btn--border-light {
    @extend %btn--border-light;
}

.btn,
.button,
[type="button"],
button {
  @extend %btn;

  &:focus {
    -webkit-tap-highlight-color: transparent;
  }
}

.btn,
.button {
    &--ghost {
        @extend %btn--ghost;

        &:active,
        &:focus,
        &:not(.disabled):hover {
            background-color: $color-muted;
        }
    }

  @each $name, $color, $background-color, $background-hover, $background-ghost, $color-white, $color-inverse, $border-lighter in $variants-list {
    &--#{$name} {
      background-color: $background-color;
      color: $color-white;
      box-shadow: inset 0 1px 0 $border-lighter, 0 1px 3px hsla(0, 0%, 0%, .2);

      &:active,
      &:focus,
      &:not(.disabled):hover {
        color: $color-white;
        background-color: $background-hover;
      }

      &:active,
      &:focus {
          box-shadow: $inset;
      }

      &.inverse-colors {
        background-color: $background-ghost;
        color: $color;
        box-shadow: none;

        &:not(.disabled):hover,
        &:active,
        &:focus {
          color: $color-inverse;
          background-color: unset;
        }
      }
    }
    &--#{$name}--border, {
      @extend %btn--border;
      background-color: $white;
      border-color: $background-color;
      color: $background-color;

      &:not(.disabled):hover,
      &:active,
      &:focus {
        color: $white;
        background-color: $background-color;
      }

      &:active,
      &:focus {
          background-color: $background-hover;
      }
    }
    &--#{$name}--border-light, {
      @extend %btn--border-light;
      border-color: $background-color;
      color: $background-color;

      &:not(.disabled):hover,
      &:active,
      &:focus {
        color: $white;
        background-color: $background-color;
      }

      &:active,
      &:focus {
          background-color: $background-hover;
      }
    }
    &--#{$name}--ghost {
        @extend %btn--ghost;

        color: $color;

        &:not(.disabled):hover,
        &:active,
        &:focus {
            color: $color;
            background: $background-ghost;
        }
    }
    &--#{$name}--link {
      @extend %btn--link;
      color: $color;

      &:not(.disabled):hover,
      &:active,
      &:focus {
        color: $color;
      }

        &.inverse-colors {
          color: $color-inverse;

          &:not(.disabled):hover,
          &:active,
          &:focus {
              color: $color-inverse;
          }
        }
    }
  }
}

// button state variants
.btn,
.button {
  &--small {
    padding: $spacer-tiny-plus $spacer-small;
    font-size: .8em;
  }

  &--big {
    padding: $spacer-small-plus $spacer-medium;
    font-size: 1.4em;
  }

  &--block {
    width: 100% !important;
    display: block;
  }

  &--unstyled {
    padding: 0;
    border: none;
    text-align: left;
    background: none;
    border-radius: 0;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    &:focus {
      box-shadow: none;
      outline: none;
    }
  }
}

// nav "burger" button
// active it with a JS toggle-class to .is-active
// recommanded HTML : <button class="nav-button" type="button" role="button" aria-label="open/close navigation"><i></i></button>
// see doc : https://knacss.com/styleguide.html#buttons
.nav-button {
  padding: 0;
  background-color: transparent;
  outline: 0;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  & > * {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: stretch;
    height: $burger-size;
    width: $burger-size;
    padding: $burger-padding;
    background-color: $burger-background;
    background-image: linear-gradient($burger-color, $burger-color);
    background-position: center;
    background-repeat: no-repeat;
    background-origin: content-box;
    background-size: 100% $burger-weight;
    transition: .25s;
    transition-property: transform, background;
    will-change: transform, background;

    &::before,
    &::after {
      content: "";
      height: $burger-weight;
      background: $burger-color;
      transition: .25s;
      transition-property: transform, top;
      will-change: transform, top;
    }
  }

  &:not(.disabled):hover {
    & > * {
      background-color: $burger-hover-background;
    }
  }

  &:focus {
    outline: 0;
  }

  &.is-active {

    & > * {
      background-image: none;
      justify-content: center;

      &::before {
        transform: translateY(50%) rotate3d(0,0,1,45deg);
      }
      &::after {
        transform: translateY(-50%) rotate3d(0,0,1,-45deg);
      }
    }
  }
}
