/* ----------------------------- */
/* ==Alerts                      */
/* ----------------------------- */
/* use .alert-- classes for variants */

.alert {
  padding: $spacer-medium $spacer-large;
  margin-top: 0;
  margin-bottom: 0;
  color: $color-base;
  border-radius: $border-radius-1;
  background-color: $color-muted;

  & .content a {
    color: inherit;
  }

  text-align: justify;

  > .row-link {
    @include font-size(smaller);
    text-align: right;
    text-align-last: right;
  }
}

.alert {
  @each $name, $border, $background, $text, $icon in $variants-list-alert {
    &--#{$name} {
      @extend .alert;
      background-color: $background;
      border-left: $border-width-3 solid $border;
      color: $text;
      box-shadow: $shadow-1;
    }
  }
}

.info {
  @each $name, $border, $background, $text, $icon in $variants-list-info {
    &--#{$name} {
      @extend .alert;
      background-color: $background;
      border: $border-width-1 solid $border;
      color: $text;

      &.info--fit {
        width: fit-content;
      }
    }
  }
}

// alert state variants
.alert {
  &--small {
    font-size: $font-size-base - 0.4rem;
  }

  &--big {
    font-size: $font-size-base + 0.4rem;
  }

  &--block {
    width: 100% !important;
    display: block;
  }
  &.disabled,
  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:empty {
    display: none;
  }
}
