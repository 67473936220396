.block {
    &.block--color {
        margin: $spacer-extra-large-plus 0 0;

        +.block {
            margin-top: $spacer-extra-large;
        }

        >.sub-block--color {
            padding: $spacer-extra-large 0;
        }

        &.block--color--nearby {
            margin: $spacer-large-plus 0 0;

            >.sub-block--color {
                padding: $spacer-large-plus 0;
            }
        }

        &.block--color--event-list {
            margin: $spacer-medium 0;

            >.sub-block--color {
                padding: $spacer-medium $spacer-tiny;

                @media (min-width: $tiny) {
                    padding: $spacer-medium $spacer-medium;
                }
            }
        }

        font-weight: 450;
        color: $white;

        h1 {
            margin-bottom: $spacer-large;
        }

        h2 {
            margin-bottom: $spacer-medium;
        }

        &::before {
            mask: url("../../images/border-top.svg") repeat-x;
            content: "";
            display: block;
            height: 40px;
            mask-size: 1450px;
        }

        &::after {
            mask: url("../../images/border-bottom.svg") repeat-x;
            content: "";
            display: block;
            height: 40px;
            mask-size: 1450px;
        }

        &.block--color-primary {
            &::before, >.sub-block--color, &::after {
                background-color: $primary-800;
            }
        }

        &.block--color-green {
            &::before, >.sub-block--color, &::after {
                background-color: hsl(125, 90%, 9%);
            }
        }

        &.block--color-black {
            &::before, >.sub-block--color, &::after {
                background-color: $gray-900;
            }
        }

//        &.block--color-search {
//            &::before, >.sub-block--color, &::after {
//                background-color: $search-600;
//                color: $gray-900;
//            }
//        }
//
//        &.block--color-secondary {
//            &::before, >.sub-block--color, &::after {
//                background-color: hsl(349, 90%, 14%);
//            }
//        }
    }
}
