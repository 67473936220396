a.event-result {
    color: $color-base;

    &:hover {
        border: $border-2;
        border-color: $link-color-hover;

        .event-name, .event-desc {
            color: $link-color-hover;
        }
    }
}

.event-result {
    .event-name {
        color: $color-base;
        font-family: $font-family-heading;
        @include font-size(h5);
        margin-bottom: $spacer-tiny-plus;
    }

    .event-desc {
        font-family: $font-family-heading;
    }
}

.event-result {
    &.event-result-small {
        .event-name {
            font-family: $font-family-heading;
            @include font-size(base);
            max-height: 3em;
            margin-bottom: $spacer-tiny-plus;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    &.event-result-link {
        display: flex;
        justify-content: center;
        align-items: center;

        .event-name {
            @include font-size(bigger);
            font-weight: $weight-regular;
        }
    }
}

.image-scale {
    display: block;
    height: 100%;
}

.icon-background {
    @extend %flex-center;
    font-size: 3rem;
    height: 100%;
    border-radius: $border-radius-2;
    border: $border-width-2 solid $color-muted;
}

.event-image-big {
    display: none;
}

.event-image-small {
    @include font-size(h6);
    text-align: center;
    height: 100px;
    width: 100px;

    .image-scale {
        box-shadow: $shadow-1;
        border-radius: $border-radius-2;
    }
}
