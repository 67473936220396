/* ----------------------------- */
/* ==Tags                      */
/* ----------------------------- */
/* use .tag-- classes for variants */

.tag {
  display: inline-block;
  padding: 3px 5px 3px 0;
  vertical-align: baseline;
  white-space: nowrap;
  color: $gray-600;
  border-radius: 0 0 $border-radius-1 0;
  border-style: solid;
  border-width: 0 1px 1px 0;
  line-height: 1;
}

.tag {
  @each $name, $color in $variants-list {
    &--#{$name} {
      @extend .tag;
      color: $color;
    }
  }
}

// tag state variants
.tag {
  &--small {
    font-size: $font-size-base - 0.4rem;
  }

  &--big {
    font-size: $font-size-base + 0.4rem;
  }

  &--block {
    width: 100% !important;
    display: block;
  }
  &.disabled,
  &--disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  &:empty {
    display: none;
  }
}
