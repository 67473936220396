@mixin font-awesome($elem) {
    $q: map-get($font-awesome, $elem);
    $mob: map-get($q, "mobile");
    $desk: map-get($q, "desktop");
    font-size: $mob;
    @include respond-to("small-up") {
        font-size: $desk;
    }
}

@mixin title-margin-top($elem) {
    $q: map-get($font-sizes, $elem);
    $mob: map-get($q, "mobile");
    $desk: map-get($q, "desktop");
    margin-top: $mob * 2;
    @include respond-to("small-up") {
        margin-top: $desk * 2;
    }
}

@mixin title-next-margin-top($elem) {
    $q: map-get($font-sizes, $elem);
    $mob: map-get($q, "mobile");
    $desk: map-get($q, "desktop");
    margin-top: $mob;
    @include respond-to("small-up") {
        margin-top: $desk;
    }
}