.cta-btn-right {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: $spacer-medium;
    align-items: center;
    text-align: center;

    > .cta-btn-right--buttons {
        display: grid;
        grid-auto-flow: row;
        grid-gap: $spacer-small;
    }

    > .cta-btn-right--action {
        text-align: center;
    }
}

.cta-big-one {
    color: $color-base;
    border-radius: $border-radius-3;
    background-color: $white-flash;
    padding: $spacer-medium-plus $spacer-large-plus;

    text-decoration: none;

    .cta-big-one--desc {
        @include font-size(smaller);
        color: $gray-600;
        padding: $spacer-small 0;
    }

    .cta-big-one--title {
        font-family: $font-family-heading;
        @include font-size(h4);
    }

    .cta-big-one--button {
        @include font-size(h6);
        text-align: center;
        padding: 0.5rem 2rem;
        border-radius: 3px;
        transition: background-color 500ms;
    }

    .cta-big-one--button--light {
        color: $color-highlight;
        text-align: center;
    }

    .cta-big-one--mention {
        color: $color-base-muted;
        @include font-size(smaller);
        text-align: center;
        height: 2em;
        margin-top: $spacer-tiny;

        @media (max-width: $medium - 1) {
            &--ghost {
                display: none;
            }
        }
    }

    &:hover {
        color: $color-base;

        div.cta-big-one--button {
            background-color: $secondary-700;
        }
    }
}
