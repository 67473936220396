@charset "UTF-8";
/* Links */
@import url("https://fonts.googleapis.com/css2?family=Spartan:wght@200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Karla:ital,wght@0,200..800;1,200..800&family=Mulish:ital,wght@0,200..1000;1,200..1000&family=Oswald:wght@200..700&display=swap");
a, .llink {
  color: hsl(199, 69%, 37%);
  text-decoration: underline;
}
a:hover, .llink:hover {
  cursor: pointer;
}
a:focus, a:hover, a:active, .llink:focus, .llink:hover, .llink:active {
  color: hsl(199, 62%, 44%);
  text-decoration: none;
}
a.llink--white, .llink.llink--white {
  color: #ffffff;
}
a.llink--white:focus, a.llink--white:hover, a.llink--white:active, .llink.llink--white:focus, .llink.llink--white:hover, .llink.llink--white:active {
  color: #ffffff;
}

.color--primary, .llink--primary {
  color: hsl(199, 69%, 37%);
}
.color--primary:focus, .color--primary:hover, .color--primary:active, .llink--primary:focus, .llink--primary:hover, .llink--primary:active {
  color: hsl(199, 69%, 37%);
}
.color--primary.inverse-colors, .llink--primary.inverse-colors {
  color: hsl(199, 64%, 59%);
}
.color--primary.inverse-colors:focus, .color--primary.inverse-colors:hover, .color--primary.inverse-colors:active, .llink--primary.inverse-colors:focus, .llink--primary.inverse-colors:hover, .llink--primary.inverse-colors:active {
  color: hsl(199, 64%, 59%);
}
.color--highlight, .llink--highlight {
  color: hsl(357, 100%, 40%);
}
.color--highlight:focus, .color--highlight:hover, .color--highlight:active, .llink--highlight:focus, .llink--highlight:hover, .llink--highlight:active {
  color: hsl(357, 100%, 40%);
}
.color--highlight.inverse-colors, .llink--highlight.inverse-colors {
  color: none;
}
.color--highlight.inverse-colors:focus, .color--highlight.inverse-colors:hover, .color--highlight.inverse-colors:active, .llink--highlight.inverse-colors:focus, .llink--highlight.inverse-colors:hover, .llink--highlight.inverse-colors:active {
  color: none;
}
.color--search, .llink--search {
  color: hsl(41, 100%, 49%);
}
.color--search:focus, .color--search:hover, .color--search:active, .llink--search:focus, .llink--search:hover, .llink--search:active {
  color: hsl(41, 100%, 49%);
}
.color--search.inverse-colors, .llink--search.inverse-colors {
  color: none;
}
.color--search.inverse-colors:focus, .color--search.inverse-colors:hover, .color--search.inverse-colors:active, .llink--search.inverse-colors:focus, .llink--search.inverse-colors:hover, .llink--search.inverse-colors:active {
  color: none;
}
.color--edit, .llink--edit {
  color: hsl(199, 69%, 31%);
}
.color--edit:focus, .color--edit:hover, .color--edit:active, .llink--edit:focus, .llink--edit:hover, .llink--edit:active {
  color: hsl(199, 69%, 31%);
}
.color--edit.inverse-colors, .llink--edit.inverse-colors {
  color: none;
}
.color--edit.inverse-colors:focus, .color--edit.inverse-colors:hover, .color--edit.inverse-colors:active, .llink--edit.inverse-colors:focus, .llink--edit.inverse-colors:hover, .llink--edit.inverse-colors:active {
  color: none;
}
.color--warning, .llink--warning {
  color: hsl(23, 100%, 45%);
}
.color--warning:focus, .color--warning:hover, .color--warning:active, .llink--warning:focus, .llink--warning:hover, .llink--warning:active {
  color: hsl(23, 100%, 45%);
}
.color--warning.inverse-colors, .llink--warning.inverse-colors {
  color: none;
}
.color--warning.inverse-colors:focus, .color--warning.inverse-colors:hover, .color--warning.inverse-colors:active, .llink--warning.inverse-colors:focus, .llink--warning.inverse-colors:hover, .llink--warning.inverse-colors:active {
  color: none;
}
.color--danger, .llink--danger {
  color: hsl(349, 100%, 24%);
}
.color--danger:focus, .color--danger:hover, .color--danger:active, .llink--danger:focus, .llink--danger:hover, .llink--danger:active {
  color: hsl(349, 100%, 24%);
}
.color--danger.inverse-colors, .llink--danger.inverse-colors {
  color: none;
}
.color--danger.inverse-colors:focus, .color--danger.inverse-colors:hover, .color--danger.inverse-colors:active, .llink--danger.inverse-colors:focus, .llink--danger.inverse-colors:hover, .llink--danger.inverse-colors:active {
  color: none;
}
.color--success, .llink--success {
  color: hsl(125, 100%, 20%);
}
.color--success:focus, .color--success:hover, .color--success:active, .llink--success:focus, .llink--success:hover, .llink--success:active {
  color: hsl(125, 100%, 20%);
}
.color--success.inverse-colors, .llink--success.inverse-colors {
  color: hsl(125, 48%, 55%);
}
.color--success.inverse-colors:focus, .color--success.inverse-colors:hover, .color--success.inverse-colors:active, .llink--success.inverse-colors:focus, .llink--success.inverse-colors:hover, .llink--success.inverse-colors:active {
  color: hsl(125, 48%, 55%);
}

#web-footer a {
  text-decoration: none;
}

p,
address,
ol,
ul,
dl,
blockquote,
pre {
  margin-top: 0;
  margin-bottom: 1rem;
}

/* Avoid margins on nested elements */
li p,
li .p-like,
li ul,
li ol,
ol ol,
ul ul {
  margin-top: 0;
  margin-bottom: 0;
}

/* Max values */
img,
table,
td,
blockquote,
code,
pre,
textarea,
input,
video,
svg {
  max-width: 100%;
}

img {
  height: auto;
}

/* Styling elements */
ul,
ol {
  padding-left: 2em;
}

img {
  vertical-align: middle;
}

em,
.italic,
address,
cite,
i,
var {
  font-style: italic;
}

code,
kbd,
mark {
  border-radius: 2px;
}

kbd {
  padding: 0 2px;
  border: 1px solid #999;
}

pre {
  tab-size: 2;
}

code {
  padding: 2px 4px;
  background: rgba(0, 0, 0, 0.04);
  color: #b11;
}

pre code {
  padding: 0;
  background: none;
  color: inherit;
  border-radius: 0;
}

mark {
  padding: 2px 4px;
}

sup,
sub {
  vertical-align: 0;
}

sup {
  bottom: 1ex;
}

sub {
  top: 0.5ex;
}

blockquote {
  position: relative;
  padding-left: 3em;
  padding-right: 3em;
  min-height: 2em;
}

blockquote::before {
  content: "“";
  position: absolute;
  left: 0;
  top: 0;
  font-family: georgia, serif;
  font-size: 5em;
  height: 0.4em;
  line-height: 0.9;
  color: hsl(4, 5%, 81%);
}

blockquote > footer {
  margin-top: 0.75em;
  font-size: 0.9em;
  color: rgba(0, 0, 0, 0.7);
}
blockquote > footer::before {
  content: "— ";
}

q {
  font-style: normal;
}

q,
.q {
  quotes: "“" "”" "‘" "’";
}
q:lang(fr),
.q:lang(fr) {
  quotes: "« " " »" "“" "”";
}

hr {
  display: block;
  clear: both;
  height: 1px;
  margin: 1em 0 2em;
  padding: 0;
  border: 0;
  color: #ccc;
  background-color: #ccc;
}

blockquote,
figure {
  margin-left: 0;
  margin-right: 0;
}

code,
pre,
samp,
kbd {
  white-space: pre-wrap;
  font-family: consolas, courier, monospace;
  line-height: normal;
}

/* ---------------------------------- */
/* ==Helpers                          */
/* ---------------------------------- */
/* Typo Helpers  */
/* ------------- */
.u-bold {
  font-weight: 600;
}

.u-italic {
  font-style: italic;
}

.u-normal {
  font-weight: normal;
  font-style: normal;
}

.u-uppercase {
  text-transform: uppercase;
}

.u-lowercase {
  text-transform: lowercase;
}

.u-smaller {
  font-size: 0.6em;
}

.u-small {
  font-size: 0.8em;
}

.u-big {
  font-size: 1.2em;
}

.u-bigger {
  font-size: 1.5em;
}

.u-biggest {
  font-size: 2em;
}

.u-txt-wrap {
  word-wrap: break-word;
  overflow-wrap: break-word;
  hyphens: auto;
}

.u-txt-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
}

/* State Helpers */
/* ------------- */
/* invisible for all */
.is-hidden,
.js-hidden,
[hidden] {
  display: none;
}

/* hidden but not for an assistive technology like a screen reader, Yahoo! method */
.visually-hidden {
  position: absolute !important;
  border: 0 !important;
  height: 1px !important;
  width: 1px !important;
  padding: 0 !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
}

.is-disabled,
.js-disabled,
[disabled],
.is-disabled ~ label,
[disabled] ~ label {
  opacity: 0.5;
  cursor: not-allowed !important;
  filter: grayscale(1);
}

ul.is-unstyled, ul.unstyled {
  list-style: none;
  padding-left: 0;
}

.color--inverse {
  color: #fafafa;
}

/* Width Helpers */
/* ------------- */
/* blocks widths (percentage and pixels) */
.w100 {
  width: 100%;
}

.w95 {
  width: 95%;
}

.w90 {
  width: 90%;
}

.w85 {
  width: 85%;
}

.w80 {
  width: 80%;
}

.w75 {
  width: 75%;
}

.w70 {
  width: 70%;
}

.w65 {
  width: 65%;
}

.w60 {
  width: 60%;
}

.w55 {
  width: 55%;
}

.w50 {
  width: 50%;
}

.w45 {
  width: 45%;
}

.w40 {
  width: 40%;
}

.w35 {
  width: 35%;
}

.w30 {
  width: 30%;
}

.w25 {
  width: 25%;
}

.w20 {
  width: 20%;
}

.w15 {
  width: 15%;
}

.w10 {
  width: 10%;
}

.w5 {
  width: 5%;
}

.w66 {
  width: 66.6666666667%;
}

.w33 {
  width: 33.3333333333%;
}

.wauto {
  width: auto;
}

.w960p {
  width: 960px;
}

.mw960p {
  max-width: 960px;
}

.w1140p {
  width: 1140px;
}

.mw1140p {
  max-width: 1140px;
}

.w1000p {
  width: 1000px;
}

.w950p {
  width: 950px;
}

.w900p {
  width: 900px;
}

.w850p {
  width: 850px;
}

.w800p {
  width: 800px;
}

.w750p {
  width: 750px;
}

.w700p {
  width: 700px;
}

.w650p {
  width: 650px;
}

.w600p {
  width: 600px;
}

.w550p {
  width: 550px;
}

.w500p {
  width: 500px;
}

.w450p {
  width: 450px;
}

.w400p {
  width: 400px;
}

.w350p {
  width: 350px;
}

.w300p {
  width: 300px;
}

.w250p {
  width: 250px;
}

.w200p {
  width: 200px;
}

.w150p {
  width: 150px;
}

.w100p {
  width: 100px;
}

.w50p {
  width: 50px;
}

/* Spacing Helpers */
/* --------------- */
.man,
.ma0 {
  margin: 0;
}

.pan,
.pa0 {
  padding: 0;
}

.mas {
  margin: 1rem;
}

.mam {
  margin: 2rem;
}

.mal {
  margin: 4rem;
}

.pas {
  padding: 1rem;
}

.pam {
  padding: 2rem;
}

.pal {
  padding: 4rem;
}

.mtn,
.mt0 {
  margin-top: 0;
}

.mts {
  margin-top: 1rem;
}

.mtm {
  margin-top: 2rem;
}

.mtl {
  margin-top: 4rem;
}

.mrn,
.mr0 {
  margin-right: 0;
}

.mrs {
  margin-right: 1rem;
}

.mrm {
  margin-right: 2rem;
}

.mrl {
  margin-right: 4rem;
}

.mbn,
.mb0 {
  margin-bottom: 0;
}

.mbs {
  margin-bottom: 1rem;
}

.mbm {
  margin-bottom: 2rem;
}

.mbl {
  margin-bottom: 4rem;
}

.mln,
.ml0 {
  margin-left: 0;
}

.mls {
  margin-left: 1rem;
}

.mlm {
  margin-left: 2rem;
}

.mll {
  margin-left: 4rem;
}

.mauto {
  margin: auto;
}

.mtauto {
  margin-top: auto;
}

.mrauto {
  margin-right: auto;
}

.mbauto {
  margin-bottom: auto;
}

.mlauto {
  margin-left: auto;
}

.ptn,
.pt0 {
  padding-top: 0;
}

.pts {
  padding-top: 1rem;
}

.ptm {
  padding-top: 2rem;
}

.ptl {
  padding-top: 4rem;
}

.prn,
.pr0 {
  padding-right: 0;
}

.prs {
  padding-right: 1rem;
}

.prm {
  padding-right: 2rem;
}

.prl {
  padding-right: 4rem;
}

.pbn,
.pb0 {
  padding-bottom: 0;
}

.pbs {
  padding-bottom: 1rem;
}

.pbm {
  padding-bottom: 2rem;
}

.pbl {
  padding-bottom: 4rem;
}

.pln,
.pl0 {
  padding-left: 0;
}

.pls {
  padding-left: 1rem;
}

.plm {
  padding-left: 2rem;
}

.pll {
  padding-left: 4rem;
}

/* ----------------------------- */
/* ==Forms                       */
/* ----------------------------- */
/* thanks to HTML5boilerplate and https://shoelace.style/ */
/* forms items */
form,
fieldset {
  border: none;
}

fieldset {
  padding: 2rem;
}
fieldset legend {
  padding: 0 0.5rem;
  border: 0;
  white-space: normal;
}

label {
  display: inline-block;
  cursor: pointer;
  margin: 0;
}

[type=color],
[type=date],
[type=datetime-local],
[type=email],
[type=month],
[type=number],
[type=password],
[type=search],
[type=submit],
[type=tel],
[type=text],
[type=time],
[type=url],
[type=week],
select,
textarea {
  white-space: nowrap;
  font-family: inherit;
  font-size: inherit;
  border: 0;
  border-radius: 0.3rem;
  box-shadow: 0 0 0 1px hsl(4, 5%, 74%) inset;
  color: hsl(4, 5%, 28%);
  vertical-align: middle;
  padding: 0.5rem 1rem;
  margin: 0;
  transition: 0.25s;
  transition-property: box-shadow, background-color, color, border;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

select {
  cursor: pointer;
  padding-right: 2rem;
  border-radius: 0.3rem;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20xmlns%3Axlink%3D%22http%3A//www.w3.org/1999/xlink%22%20style%3D%22isolation%3Aisolate%22%20viewBox%3D%220%200%2020%2020%22%20width%3D%2220%22%20height%3D%2220%22%3E%3Cpath%20d%3D%22%20M%209.96%2011.966%20L%203.523%205.589%20C%202.464%204.627%200.495%206.842%201.505%207.771%20L%201.505%207.771%20L%208.494%2014.763%20C%209.138%2015.35%2010.655%2015.369%2011.29%2014.763%20L%2011.29%2014.763%20L%2018.49%207.771%20C%2019.557%206.752%2017.364%204.68%2016.262%205.725%20L%2016.262%205.725%20L%209.96%2011.966%20Z%20%22%20fill%3D%22inherit%22/%3E%3C/svg%3E");
  background-position: right 0.6rem center;
  background-repeat: no-repeat;
  background-size: 1.2rem;
}

/* hiding IE11 arrow */
select::-ms-expand {
  display: none;
}

textarea {
  min-height: 10em;
  vertical-align: top;
  resize: vertical;
  white-space: pre-wrap;
}

/* 'x' appears on right of search input when text is entered. This removes it */
[type=search]::-webkit-search-decoration, [type=search]::-webkit-search-cancel-button, [type=search]::-webkit-search-results-button, [type=search]::-webkit-search-results-decoration {
  display: none;
}

::-webkit-input-placeholder {
  color: #777;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #777;
}

progress {
  width: 100%;
  vertical-align: middle;
}

/* ----------------------------- */
/* ==Buttons                     */
/* ----------------------------- */
/* preferably use <button> for buttons !*/
/* use .btn-- or .button-- classes for variants */
.btn,
.button,
[type=button],
button {
  display: inline-block;
  padding: 1rem 1.5rem;
  user-select: none;
  transition: 0.25s;
  transition-property: box-shadow, background-color, color, border;
  vertical-align: middle;
  white-space: nowrap;
  text-align: center;
  text-decoration: none;
  color: hsl(4, 5%, 28%);
  border: none;
  border-radius: 0.3rem;
  background-color: hsl(4, 5%, 90%);
  font-family: inherit;
  font-size: inherit;
  line-height: 1;
}
.btn:not(.disabled),
.button:not(.disabled),
[type=button]:not(.disabled),
button:not(.disabled) {
  cursor: pointer;
}

.btn--success--border,
.button--success--border, .btn--danger--border,
.button--danger--border, .btn--warning--border,
.button--warning--border, .btn--edit--border,
.button--edit--border, .btn--search--border,
.button--search--border, .btn--highlight--border,
.button--highlight--border, .btn--primary--border,
.button--primary--border, .btn--success--border-light,
.button--success--border-light, .btn--danger--border-light,
.button--danger--border-light, .btn--warning--border-light,
.button--warning--border-light, .btn--edit--border-light,
.button--edit--border-light, .btn--search--border-light,
.button--search--border-light, .btn--highlight--border-light,
.button--highlight--border-light, .btn--primary--border-light,
.button--primary--border-light, .btn--border-light {
  background-color: inherit;
  padding: calc(1rem - 2px) 1.5rem;
  border-style: solid;
  border-width: 2px;
  box-shadow: none;
}

.btn--success--border-light,
.button--success--border-light, .btn--danger--border-light,
.button--danger--border-light, .btn--warning--border-light,
.button--warning--border-light, .btn--edit--border-light,
.button--edit--border-light, .btn--search--border-light,
.button--search--border-light, .btn--highlight--border-light,
.button--highlight--border-light, .btn--primary--border-light,
.button--primary--border-light, .btn--border-light {
  padding: calc(1rem - 1px) 1.5rem;
  border-width: 1px;
}

.btn--success--link,
.button--success--link, .btn--danger--link,
.button--danger--link, .btn--warning--link,
.button--warning--link, .btn--edit--link,
.button--edit--link, .btn--search--link,
.button--search--link, .btn--highlight--link,
.button--highlight--link, .btn--primary--link,
.button--primary--link {
  background-color: inherit;
  box-shadow: none;
  text-decoration: underline;
}
.btn--success--link:not(.disabled):hover,
.button--success--link:not(.disabled):hover, .btn--danger--link:not(.disabled):hover,
.button--danger--link:not(.disabled):hover, .btn--warning--link:not(.disabled):hover,
.button--warning--link:not(.disabled):hover, .btn--edit--link:not(.disabled):hover,
.button--edit--link:not(.disabled):hover, .btn--search--link:not(.disabled):hover,
.button--search--link:not(.disabled):hover, .btn--highlight--link:not(.disabled):hover,
.button--highlight--link:not(.disabled):hover, .btn--primary--link:not(.disabled):hover,
.button--primary--link:not(.disabled):hover {
  text-decoration: none;
}

.btn--success--ghost,
.button--success--ghost, .btn--danger--ghost,
.button--danger--ghost, .btn--warning--ghost,
.button--warning--ghost, .btn--edit--ghost,
.button--edit--ghost, .btn--search--ghost,
.button--search--ghost, .btn--highlight--ghost,
.button--highlight--ghost, .btn--primary--ghost,
.button--primary--ghost, .btn--ghost,
.button--ghost {
  background-color: inherit;
  box-shadow: none;
}

.btn:focus,
.button:focus,
[type=button]:focus,
button:focus {
  -webkit-tap-highlight-color: transparent;
}

.btn--ghost:active, .btn--ghost:focus, .btn--ghost:not(.disabled):hover,
.button--ghost:active,
.button--ghost:focus,
.button--ghost:not(.disabled):hover {
  background-color: hsl(4, 5%, 90%);
}
.btn--primary,
.button--primary {
  background-color: hsl(199, 69%, 31%);
  color: #fafafa;
  box-shadow: inset 0 1px 0 hsl(199, 64%, 59%), 0 1px 3px hsla(0, 0%, 0%, 0.2);
}
.btn--primary:active, .btn--primary:focus, .btn--primary:not(.disabled):hover,
.button--primary:active,
.button--primary:focus,
.button--primary:not(.disabled):hover {
  color: #fafafa;
  background-color: hsl(199, 72%, 24%);
}
.btn--primary:active, .btn--primary:focus,
.button--primary:active,
.button--primary:focus {
  box-shadow: 0 2px 0 hsla(0, 0%, 100%, 0.15), inset 0 2px 2px hsla(0, 0%, 0%, 0.1);
}
.btn--primary.inverse-colors,
.button--primary.inverse-colors {
  background-color: hsl(199, 35%, 88%);
  color: hsl(199, 69%, 37%);
  box-shadow: none;
}
.btn--primary.inverse-colors:not(.disabled):hover, .btn--primary.inverse-colors:active, .btn--primary.inverse-colors:focus,
.button--primary.inverse-colors:not(.disabled):hover,
.button--primary.inverse-colors:active,
.button--primary.inverse-colors:focus {
  color: hsl(199, 64%, 59%);
  background-color: unset;
}
.btn--primary--border,
.button--primary--border {
  background-color: #fafafa;
  border-color: hsl(199, 69%, 31%);
  color: hsl(199, 69%, 31%);
}
.btn--primary--border:not(.disabled):hover, .btn--primary--border:active, .btn--primary--border:focus,
.button--primary--border:not(.disabled):hover,
.button--primary--border:active,
.button--primary--border:focus {
  color: #fafafa;
  background-color: hsl(199, 69%, 31%);
}
.btn--primary--border:active, .btn--primary--border:focus,
.button--primary--border:active,
.button--primary--border:focus {
  background-color: hsl(199, 72%, 24%);
}
.btn--primary--border-light,
.button--primary--border-light {
  border-color: hsl(199, 69%, 31%);
  color: hsl(199, 69%, 31%);
}
.btn--primary--border-light:not(.disabled):hover, .btn--primary--border-light:active, .btn--primary--border-light:focus,
.button--primary--border-light:not(.disabled):hover,
.button--primary--border-light:active,
.button--primary--border-light:focus {
  color: #fafafa;
  background-color: hsl(199, 69%, 31%);
}
.btn--primary--border-light:active, .btn--primary--border-light:focus,
.button--primary--border-light:active,
.button--primary--border-light:focus {
  background-color: hsl(199, 72%, 24%);
}
.btn--primary--ghost,
.button--primary--ghost {
  color: hsl(199, 69%, 37%);
}
.btn--primary--ghost:not(.disabled):hover, .btn--primary--ghost:active, .btn--primary--ghost:focus,
.button--primary--ghost:not(.disabled):hover,
.button--primary--ghost:active,
.button--primary--ghost:focus {
  color: hsl(199, 69%, 37%);
  background: hsl(199, 35%, 88%);
}
.btn--primary--link,
.button--primary--link {
  color: hsl(199, 69%, 37%);
}
.btn--primary--link:not(.disabled):hover, .btn--primary--link:active, .btn--primary--link:focus,
.button--primary--link:not(.disabled):hover,
.button--primary--link:active,
.button--primary--link:focus {
  color: hsl(199, 69%, 37%);
}
.btn--primary--link.inverse-colors,
.button--primary--link.inverse-colors {
  color: hsl(199, 64%, 59%);
}
.btn--primary--link.inverse-colors:not(.disabled):hover, .btn--primary--link.inverse-colors:active, .btn--primary--link.inverse-colors:focus,
.button--primary--link.inverse-colors:not(.disabled):hover,
.button--primary--link.inverse-colors:active,
.button--primary--link.inverse-colors:focus {
  color: hsl(199, 64%, 59%);
}
.btn--highlight,
.button--highlight {
  background-color: hsl(2, 78%, 51%);
  color: #ffffff;
  box-shadow: inset 0 1px 0 hsl(20, 100%, 71%), 0 1px 3px hsla(0, 0%, 0%, 0.2);
}
.btn--highlight:active, .btn--highlight:focus, .btn--highlight:not(.disabled):hover,
.button--highlight:active,
.button--highlight:focus,
.button--highlight:not(.disabled):hover {
  color: #ffffff;
  background-color: hsl(357, 100%, 40%);
}
.btn--highlight:active, .btn--highlight:focus,
.button--highlight:active,
.button--highlight:focus {
  box-shadow: 0 2px 0 hsla(0, 0%, 100%, 0.15), inset 0 2px 2px hsla(0, 0%, 0%, 0.1);
}
.btn--highlight.inverse-colors,
.button--highlight.inverse-colors {
  background-color: hsl(30, 35%, 88%);
  color: hsl(357, 100%, 40%);
  box-shadow: none;
}
.btn--highlight.inverse-colors:not(.disabled):hover, .btn--highlight.inverse-colors:active, .btn--highlight.inverse-colors:focus,
.button--highlight.inverse-colors:not(.disabled):hover,
.button--highlight.inverse-colors:active,
.button--highlight.inverse-colors:focus {
  color: none;
  background-color: unset;
}
.btn--highlight--border,
.button--highlight--border {
  background-color: #fafafa;
  border-color: hsl(2, 78%, 51%);
  color: hsl(2, 78%, 51%);
}
.btn--highlight--border:not(.disabled):hover, .btn--highlight--border:active, .btn--highlight--border:focus,
.button--highlight--border:not(.disabled):hover,
.button--highlight--border:active,
.button--highlight--border:focus {
  color: #fafafa;
  background-color: hsl(2, 78%, 51%);
}
.btn--highlight--border:active, .btn--highlight--border:focus,
.button--highlight--border:active,
.button--highlight--border:focus {
  background-color: hsl(357, 100%, 40%);
}
.btn--highlight--border-light,
.button--highlight--border-light {
  border-color: hsl(2, 78%, 51%);
  color: hsl(2, 78%, 51%);
}
.btn--highlight--border-light:not(.disabled):hover, .btn--highlight--border-light:active, .btn--highlight--border-light:focus,
.button--highlight--border-light:not(.disabled):hover,
.button--highlight--border-light:active,
.button--highlight--border-light:focus {
  color: #fafafa;
  background-color: hsl(2, 78%, 51%);
}
.btn--highlight--border-light:active, .btn--highlight--border-light:focus,
.button--highlight--border-light:active,
.button--highlight--border-light:focus {
  background-color: hsl(357, 100%, 40%);
}
.btn--highlight--ghost,
.button--highlight--ghost {
  color: hsl(357, 100%, 40%);
}
.btn--highlight--ghost:not(.disabled):hover, .btn--highlight--ghost:active, .btn--highlight--ghost:focus,
.button--highlight--ghost:not(.disabled):hover,
.button--highlight--ghost:active,
.button--highlight--ghost:focus {
  color: hsl(357, 100%, 40%);
  background: hsl(30, 35%, 88%);
}
.btn--highlight--link,
.button--highlight--link {
  color: hsl(357, 100%, 40%);
}
.btn--highlight--link:not(.disabled):hover, .btn--highlight--link:active, .btn--highlight--link:focus,
.button--highlight--link:not(.disabled):hover,
.button--highlight--link:active,
.button--highlight--link:focus {
  color: hsl(357, 100%, 40%);
}
.btn--highlight--link.inverse-colors,
.button--highlight--link.inverse-colors {
  color: none;
}
.btn--highlight--link.inverse-colors:not(.disabled):hover, .btn--highlight--link.inverse-colors:active, .btn--highlight--link.inverse-colors:focus,
.button--highlight--link.inverse-colors:not(.disabled):hover,
.button--highlight--link.inverse-colors:active,
.button--highlight--link.inverse-colors:focus {
  color: none;
}
.btn--search,
.button--search {
  background-color: hsl(46, 100%, 50%);
  color: hsl(4, 5%, 28%);
  box-shadow: inset 0 1px 0 hsl(50, 100%, 63%), 0 1px 3px hsla(0, 0%, 0%, 0.2);
}
.btn--search:active, .btn--search:focus, .btn--search:not(.disabled):hover,
.button--search:active,
.button--search:focus,
.button--search:not(.disabled):hover {
  color: hsl(4, 5%, 28%);
  background-color: hsl(41, 100%, 49%);
}
.btn--search:active, .btn--search:focus,
.button--search:active,
.button--search:focus {
  box-shadow: 0 2px 0 hsla(0, 0%, 100%, 0.15), inset 0 2px 2px hsla(0, 0%, 0%, 0.1);
}
.btn--search.inverse-colors,
.button--search.inverse-colors {
  background-color: hsl(50, 100%, 63%);
  color: hsl(41, 100%, 49%);
  box-shadow: none;
}
.btn--search.inverse-colors:not(.disabled):hover, .btn--search.inverse-colors:active, .btn--search.inverse-colors:focus,
.button--search.inverse-colors:not(.disabled):hover,
.button--search.inverse-colors:active,
.button--search.inverse-colors:focus {
  color: none;
  background-color: unset;
}
.btn--search--border,
.button--search--border {
  background-color: #fafafa;
  border-color: hsl(46, 100%, 50%);
  color: hsl(46, 100%, 50%);
}
.btn--search--border:not(.disabled):hover, .btn--search--border:active, .btn--search--border:focus,
.button--search--border:not(.disabled):hover,
.button--search--border:active,
.button--search--border:focus {
  color: #fafafa;
  background-color: hsl(46, 100%, 50%);
}
.btn--search--border:active, .btn--search--border:focus,
.button--search--border:active,
.button--search--border:focus {
  background-color: hsl(41, 100%, 49%);
}
.btn--search--border-light,
.button--search--border-light {
  border-color: hsl(46, 100%, 50%);
  color: hsl(46, 100%, 50%);
}
.btn--search--border-light:not(.disabled):hover, .btn--search--border-light:active, .btn--search--border-light:focus,
.button--search--border-light:not(.disabled):hover,
.button--search--border-light:active,
.button--search--border-light:focus {
  color: #fafafa;
  background-color: hsl(46, 100%, 50%);
}
.btn--search--border-light:active, .btn--search--border-light:focus,
.button--search--border-light:active,
.button--search--border-light:focus {
  background-color: hsl(41, 100%, 49%);
}
.btn--search--ghost,
.button--search--ghost {
  color: hsl(41, 100%, 49%);
}
.btn--search--ghost:not(.disabled):hover, .btn--search--ghost:active, .btn--search--ghost:focus,
.button--search--ghost:not(.disabled):hover,
.button--search--ghost:active,
.button--search--ghost:focus {
  color: hsl(41, 100%, 49%);
  background: hsl(50, 100%, 63%);
}
.btn--search--link,
.button--search--link {
  color: hsl(41, 100%, 49%);
}
.btn--search--link:not(.disabled):hover, .btn--search--link:active, .btn--search--link:focus,
.button--search--link:not(.disabled):hover,
.button--search--link:active,
.button--search--link:focus {
  color: hsl(41, 100%, 49%);
}
.btn--search--link.inverse-colors,
.button--search--link.inverse-colors {
  color: none;
}
.btn--search--link.inverse-colors:not(.disabled):hover, .btn--search--link.inverse-colors:active, .btn--search--link.inverse-colors:focus,
.button--search--link.inverse-colors:not(.disabled):hover,
.button--search--link.inverse-colors:active,
.button--search--link.inverse-colors:focus {
  color: none;
}
.btn--edit,
.button--edit {
  background-color: hsl(199, 70%, 83%);
  color: hsl(199, 69%, 31%);
  box-shadow: inset 0 1px 0 hsl(199, 70%, 83%), 0 1px 3px hsla(0, 0%, 0%, 0.2);
}
.btn--edit:active, .btn--edit:focus, .btn--edit:not(.disabled):hover,
.button--edit:active,
.button--edit:focus,
.button--edit:not(.disabled):hover {
  color: hsl(199, 69%, 31%);
  background-color: hsl(199, 66%, 71%);
}
.btn--edit:active, .btn--edit:focus,
.button--edit:active,
.button--edit:focus {
  box-shadow: 0 2px 0 hsla(0, 0%, 100%, 0.15), inset 0 2px 2px hsla(0, 0%, 0%, 0.1);
}
.btn--edit.inverse-colors,
.button--edit.inverse-colors {
  background-color: hsl(199, 35%, 88%);
  color: hsl(199, 69%, 31%);
  box-shadow: none;
}
.btn--edit.inverse-colors:not(.disabled):hover, .btn--edit.inverse-colors:active, .btn--edit.inverse-colors:focus,
.button--edit.inverse-colors:not(.disabled):hover,
.button--edit.inverse-colors:active,
.button--edit.inverse-colors:focus {
  color: none;
  background-color: unset;
}
.btn--edit--border,
.button--edit--border {
  background-color: #fafafa;
  border-color: hsl(199, 70%, 83%);
  color: hsl(199, 70%, 83%);
}
.btn--edit--border:not(.disabled):hover, .btn--edit--border:active, .btn--edit--border:focus,
.button--edit--border:not(.disabled):hover,
.button--edit--border:active,
.button--edit--border:focus {
  color: #fafafa;
  background-color: hsl(199, 70%, 83%);
}
.btn--edit--border:active, .btn--edit--border:focus,
.button--edit--border:active,
.button--edit--border:focus {
  background-color: hsl(199, 66%, 71%);
}
.btn--edit--border-light,
.button--edit--border-light {
  border-color: hsl(199, 70%, 83%);
  color: hsl(199, 70%, 83%);
}
.btn--edit--border-light:not(.disabled):hover, .btn--edit--border-light:active, .btn--edit--border-light:focus,
.button--edit--border-light:not(.disabled):hover,
.button--edit--border-light:active,
.button--edit--border-light:focus {
  color: #fafafa;
  background-color: hsl(199, 70%, 83%);
}
.btn--edit--border-light:active, .btn--edit--border-light:focus,
.button--edit--border-light:active,
.button--edit--border-light:focus {
  background-color: hsl(199, 66%, 71%);
}
.btn--edit--ghost,
.button--edit--ghost {
  color: hsl(199, 69%, 31%);
}
.btn--edit--ghost:not(.disabled):hover, .btn--edit--ghost:active, .btn--edit--ghost:focus,
.button--edit--ghost:not(.disabled):hover,
.button--edit--ghost:active,
.button--edit--ghost:focus {
  color: hsl(199, 69%, 31%);
  background: hsl(199, 35%, 88%);
}
.btn--edit--link,
.button--edit--link {
  color: hsl(199, 69%, 31%);
}
.btn--edit--link:not(.disabled):hover, .btn--edit--link:active, .btn--edit--link:focus,
.button--edit--link:not(.disabled):hover,
.button--edit--link:active,
.button--edit--link:focus {
  color: hsl(199, 69%, 31%);
}
.btn--edit--link.inverse-colors,
.button--edit--link.inverse-colors {
  color: none;
}
.btn--edit--link.inverse-colors:not(.disabled):hover, .btn--edit--link.inverse-colors:active, .btn--edit--link.inverse-colors:focus,
.button--edit--link.inverse-colors:not(.disabled):hover,
.button--edit--link.inverse-colors:active,
.button--edit--link.inverse-colors:focus {
  color: none;
}
.btn--warning,
.button--warning {
  background-color: hsl(30, 100%, 50%);
  color: #fafafa;
  box-shadow: inset 0 1px 0 hsl(30, 100%, 70%), 0 1px 3px hsla(0, 0%, 0%, 0.2);
}
.btn--warning:active, .btn--warning:focus, .btn--warning:not(.disabled):hover,
.button--warning:active,
.button--warning:focus,
.button--warning:not(.disabled):hover {
  color: #fafafa;
  background-color: hsl(23, 100%, 45%);
}
.btn--warning:active, .btn--warning:focus,
.button--warning:active,
.button--warning:focus {
  box-shadow: 0 2px 0 hsla(0, 0%, 100%, 0.15), inset 0 2px 2px hsla(0, 0%, 0%, 0.1);
}
.btn--warning.inverse-colors,
.button--warning.inverse-colors {
  background-color: hsl(30, 100%, 70%);
  color: hsl(23, 100%, 45%);
  box-shadow: none;
}
.btn--warning.inverse-colors:not(.disabled):hover, .btn--warning.inverse-colors:active, .btn--warning.inverse-colors:focus,
.button--warning.inverse-colors:not(.disabled):hover,
.button--warning.inverse-colors:active,
.button--warning.inverse-colors:focus {
  color: none;
  background-color: unset;
}
.btn--warning--border,
.button--warning--border {
  background-color: #fafafa;
  border-color: hsl(30, 100%, 50%);
  color: hsl(30, 100%, 50%);
}
.btn--warning--border:not(.disabled):hover, .btn--warning--border:active, .btn--warning--border:focus,
.button--warning--border:not(.disabled):hover,
.button--warning--border:active,
.button--warning--border:focus {
  color: #fafafa;
  background-color: hsl(30, 100%, 50%);
}
.btn--warning--border:active, .btn--warning--border:focus,
.button--warning--border:active,
.button--warning--border:focus {
  background-color: hsl(23, 100%, 45%);
}
.btn--warning--border-light,
.button--warning--border-light {
  border-color: hsl(30, 100%, 50%);
  color: hsl(30, 100%, 50%);
}
.btn--warning--border-light:not(.disabled):hover, .btn--warning--border-light:active, .btn--warning--border-light:focus,
.button--warning--border-light:not(.disabled):hover,
.button--warning--border-light:active,
.button--warning--border-light:focus {
  color: #fafafa;
  background-color: hsl(30, 100%, 50%);
}
.btn--warning--border-light:active, .btn--warning--border-light:focus,
.button--warning--border-light:active,
.button--warning--border-light:focus {
  background-color: hsl(23, 100%, 45%);
}
.btn--warning--ghost,
.button--warning--ghost {
  color: hsl(23, 100%, 45%);
}
.btn--warning--ghost:not(.disabled):hover, .btn--warning--ghost:active, .btn--warning--ghost:focus,
.button--warning--ghost:not(.disabled):hover,
.button--warning--ghost:active,
.button--warning--ghost:focus {
  color: hsl(23, 100%, 45%);
  background: hsl(30, 100%, 70%);
}
.btn--warning--link,
.button--warning--link {
  color: hsl(23, 100%, 45%);
}
.btn--warning--link:not(.disabled):hover, .btn--warning--link:active, .btn--warning--link:focus,
.button--warning--link:not(.disabled):hover,
.button--warning--link:active,
.button--warning--link:focus {
  color: hsl(23, 100%, 45%);
}
.btn--warning--link.inverse-colors,
.button--warning--link.inverse-colors {
  color: none;
}
.btn--warning--link.inverse-colors:not(.disabled):hover, .btn--warning--link.inverse-colors:active, .btn--warning--link.inverse-colors:focus,
.button--warning--link.inverse-colors:not(.disabled):hover,
.button--warning--link.inverse-colors:active,
.button--warning--link.inverse-colors:focus {
  color: none;
}
.btn--danger,
.button--danger {
  background-color: hsl(353, 100%, 32%);
  color: #fafafa;
  box-shadow: inset 0 1px 0 hsl(2, 78%, 51%), 0 1px 3px hsla(0, 0%, 0%, 0.2);
}
.btn--danger:active, .btn--danger:focus, .btn--danger:not(.disabled):hover,
.button--danger:active,
.button--danger:focus,
.button--danger:not(.disabled):hover {
  color: #fafafa;
  background-color: hsl(349, 100%, 24%);
}
.btn--danger:active, .btn--danger:focus,
.button--danger:active,
.button--danger:focus {
  box-shadow: 0 2px 0 hsla(0, 0%, 100%, 0.15), inset 0 2px 2px hsla(0, 0%, 0%, 0.1);
}
.btn--danger.inverse-colors,
.button--danger.inverse-colors {
  background-color: hsl(30, 35%, 88%);
  color: hsl(349, 100%, 24%);
  box-shadow: none;
}
.btn--danger.inverse-colors:not(.disabled):hover, .btn--danger.inverse-colors:active, .btn--danger.inverse-colors:focus,
.button--danger.inverse-colors:not(.disabled):hover,
.button--danger.inverse-colors:active,
.button--danger.inverse-colors:focus {
  color: none;
  background-color: unset;
}
.btn--danger--border,
.button--danger--border {
  background-color: #fafafa;
  border-color: hsl(353, 100%, 32%);
  color: hsl(353, 100%, 32%);
}
.btn--danger--border:not(.disabled):hover, .btn--danger--border:active, .btn--danger--border:focus,
.button--danger--border:not(.disabled):hover,
.button--danger--border:active,
.button--danger--border:focus {
  color: #fafafa;
  background-color: hsl(353, 100%, 32%);
}
.btn--danger--border:active, .btn--danger--border:focus,
.button--danger--border:active,
.button--danger--border:focus {
  background-color: hsl(349, 100%, 24%);
}
.btn--danger--border-light,
.button--danger--border-light {
  border-color: hsl(353, 100%, 32%);
  color: hsl(353, 100%, 32%);
}
.btn--danger--border-light:not(.disabled):hover, .btn--danger--border-light:active, .btn--danger--border-light:focus,
.button--danger--border-light:not(.disabled):hover,
.button--danger--border-light:active,
.button--danger--border-light:focus {
  color: #fafafa;
  background-color: hsl(353, 100%, 32%);
}
.btn--danger--border-light:active, .btn--danger--border-light:focus,
.button--danger--border-light:active,
.button--danger--border-light:focus {
  background-color: hsl(349, 100%, 24%);
}
.btn--danger--ghost,
.button--danger--ghost {
  color: hsl(349, 100%, 24%);
}
.btn--danger--ghost:not(.disabled):hover, .btn--danger--ghost:active, .btn--danger--ghost:focus,
.button--danger--ghost:not(.disabled):hover,
.button--danger--ghost:active,
.button--danger--ghost:focus {
  color: hsl(349, 100%, 24%);
  background: hsl(30, 35%, 88%);
}
.btn--danger--link,
.button--danger--link {
  color: hsl(349, 100%, 24%);
}
.btn--danger--link:not(.disabled):hover, .btn--danger--link:active, .btn--danger--link:focus,
.button--danger--link:not(.disabled):hover,
.button--danger--link:active,
.button--danger--link:focus {
  color: hsl(349, 100%, 24%);
}
.btn--danger--link.inverse-colors,
.button--danger--link.inverse-colors {
  color: none;
}
.btn--danger--link.inverse-colors:not(.disabled):hover, .btn--danger--link.inverse-colors:active, .btn--danger--link.inverse-colors:focus,
.button--danger--link.inverse-colors:not(.disabled):hover,
.button--danger--link.inverse-colors:active,
.button--danger--link.inverse-colors:focus {
  color: none;
}
.btn--success,
.button--success {
  background-color: hsl(125, 62%, 33%);
  color: #ffffff;
  box-shadow: inset 0 1px 0 hsl(125, 48%, 55%), 0 1px 3px hsla(0, 0%, 0%, 0.2);
}
.btn--success:active, .btn--success:focus, .btn--success:not(.disabled):hover,
.button--success:active,
.button--success:focus,
.button--success:not(.disabled):hover {
  color: #ffffff;
  background-color: hsl(125, 53%, 45%);
}
.btn--success:active, .btn--success:focus,
.button--success:active,
.button--success:focus {
  box-shadow: 0 2px 0 hsla(0, 0%, 100%, 0.15), inset 0 2px 2px hsla(0, 0%, 0%, 0.1);
}
.btn--success.inverse-colors,
.button--success.inverse-colors {
  background-color: hsl(125, 75%, 83%);
  color: hsl(125, 100%, 20%);
  box-shadow: none;
}
.btn--success.inverse-colors:not(.disabled):hover, .btn--success.inverse-colors:active, .btn--success.inverse-colors:focus,
.button--success.inverse-colors:not(.disabled):hover,
.button--success.inverse-colors:active,
.button--success.inverse-colors:focus {
  color: hsl(125, 48%, 55%);
  background-color: unset;
}
.btn--success--border,
.button--success--border {
  background-color: #fafafa;
  border-color: hsl(125, 62%, 33%);
  color: hsl(125, 62%, 33%);
}
.btn--success--border:not(.disabled):hover, .btn--success--border:active, .btn--success--border:focus,
.button--success--border:not(.disabled):hover,
.button--success--border:active,
.button--success--border:focus {
  color: #fafafa;
  background-color: hsl(125, 62%, 33%);
}
.btn--success--border:active, .btn--success--border:focus,
.button--success--border:active,
.button--success--border:focus {
  background-color: hsl(125, 53%, 45%);
}
.btn--success--border-light,
.button--success--border-light {
  border-color: hsl(125, 62%, 33%);
  color: hsl(125, 62%, 33%);
}
.btn--success--border-light:not(.disabled):hover, .btn--success--border-light:active, .btn--success--border-light:focus,
.button--success--border-light:not(.disabled):hover,
.button--success--border-light:active,
.button--success--border-light:focus {
  color: #fafafa;
  background-color: hsl(125, 62%, 33%);
}
.btn--success--border-light:active, .btn--success--border-light:focus,
.button--success--border-light:active,
.button--success--border-light:focus {
  background-color: hsl(125, 53%, 45%);
}
.btn--success--ghost,
.button--success--ghost {
  color: hsl(125, 100%, 20%);
}
.btn--success--ghost:not(.disabled):hover, .btn--success--ghost:active, .btn--success--ghost:focus,
.button--success--ghost:not(.disabled):hover,
.button--success--ghost:active,
.button--success--ghost:focus {
  color: hsl(125, 100%, 20%);
  background: hsl(125, 75%, 83%);
}
.btn--success--link,
.button--success--link {
  color: hsl(125, 100%, 20%);
}
.btn--success--link:not(.disabled):hover, .btn--success--link:active, .btn--success--link:focus,
.button--success--link:not(.disabled):hover,
.button--success--link:active,
.button--success--link:focus {
  color: hsl(125, 100%, 20%);
}
.btn--success--link.inverse-colors,
.button--success--link.inverse-colors {
  color: hsl(125, 48%, 55%);
}
.btn--success--link.inverse-colors:not(.disabled):hover, .btn--success--link.inverse-colors:active, .btn--success--link.inverse-colors:focus,
.button--success--link.inverse-colors:not(.disabled):hover,
.button--success--link.inverse-colors:active,
.button--success--link.inverse-colors:focus {
  color: hsl(125, 48%, 55%);
}

.btn--small,
.button--small {
  padding: 0.7rem 1rem;
  font-size: 0.8em;
}
.btn--big,
.button--big {
  padding: 1.5rem 2rem;
  font-size: 1.4em;
}
.btn--block,
.button--block {
  width: 100% !important;
  display: block;
}
.btn--unstyled,
.button--unstyled {
  padding: 0;
  border: none;
  text-align: left;
  background: none;
  border-radius: 0;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.btn--unstyled:focus,
.button--unstyled:focus {
  box-shadow: none;
  outline: none;
}

.nav-button {
  padding: 0;
  background-color: transparent;
  outline: 0;
  border: 0;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
}
.nav-button > * {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: 2.6rem;
  width: 2.6rem;
  padding: 0;
  background-color: transparent;
  background-image: linear-gradient(hsl(4, 5%, 19%), hsl(4, 5%, 19%));
  background-position: center;
  background-repeat: no-repeat;
  background-origin: content-box;
  background-size: 100% 5px;
  transition: 0.25s;
  transition-property: transform, background;
  will-change: transform, background;
}
.nav-button > *::before, .nav-button > *::after {
  content: "";
  height: 5px;
  background: hsl(4, 5%, 19%);
  transition: 0.25s;
  transition-property: transform, top;
  will-change: transform, top;
}
.nav-button:not(.disabled):hover > * {
  background-color: transparent;
}
.nav-button:focus {
  outline: 0;
}
.nav-button.is-active > * {
  background-image: none;
  justify-content: center;
}
.nav-button.is-active > *::before {
  transform: translateY(50%) rotate3d(0, 0, 1, 45deg);
}
.nav-button.is-active > *::after {
  transform: translateY(-50%) rotate3d(0, 0, 1, -45deg);
}

/* ----------------------------- */
/* ==Checkbox, radio, switch     */
/* ----------------------------- */
/* use .checkbox class on input type=checkbox */
/* recommanded HTML : <input type="checkbox" class="checkbox" id="c1"><label for="c1">click here</label> */
/* use .radio class on input type=radio */
/* recommanded HTML : <input type="radio" class="radio" name="radio" id="r1"><label for="r1">Click here</label> */
/* use .switch class on input type=checkbox */
.checkbox {
  border-radius: 0.3rem;
}

.switch {
  border-radius: 3em;
}

.radio {
  border-radius: 50%;
}

.switch,
.checkbox,
.radio {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  vertical-align: text-bottom;
  outline: 0;
  cursor: pointer;
  box-shadow: inset 0 0 0 1px hsl(7, 5%, 63%);
  background: #fff;
}
.switch ~ label,
.checkbox ~ label,
.radio ~ label {
  cursor: pointer;
}
.switch::-ms-check,
.checkbox::-ms-check,
.radio::-ms-check {
  display: none;
}

.switch {
  width: 4rem;
  height: 2rem;
  line-height: 2rem;
  font-size: 60%;
  box-shadow: inset -2rem 0 0 hsl(7, 5%, 63%), inset 0 0 0 1px hsl(7, 5%, 63%);
  transition: box-shadow 0.15s;
}
.switch::before, .switch::after {
  font-weight: bold;
  color: #fff;
}
.switch::before {
  content: "✕";
  float: right;
  margin-right: 0.6666666667rem;
}
.switch:checked {
  box-shadow: inset 2rem 0 0 hsl(125, 48%, 55%), inset 0 0 0 1px hsl(125, 48%, 55%);
}
.switch:checked::before {
  content: "✓";
  float: left;
  margin-left: 0.6666666667rem;
}

.checkbox {
  width: 2rem;
  height: 2rem;
  transition: background-color 0.15s;
}
.checkbox:checked {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4NCjwhLS0gR2VuZXJhdG9yOiBBZG9iZSBJbGx1c3RyYXRvciAxNy4xLjAsIFNWRyBFeHBvcnQgUGx1Zy1JbiAuIFNWRyBWZXJzaW9uOiA2LjAwIEJ1aWxkIDApICAtLT4NCjwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DQo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4Ig0KCSB2aWV3Qm94PSIwIDAgOCA4IiBlbmFibGUtYmFja2dyb3VuZD0ibmV3IDAgMCA4IDgiIHhtbDpzcGFjZT0icHJlc2VydmUiPg0KPHBhdGggZmlsbD0iI0ZGRkZGRiIgZD0iTTYuNCwxTDUuNywxLjdMMi45LDQuNUwyLjEsMy43TDEuNCwzTDAsNC40bDAuNywwLjdsMS41LDEuNWwwLjcsMC43bDAuNy0wLjdsMy41LTMuNWwwLjctMC43TDYuNCwxTDYuNCwxeiINCgkvPg0KPC9zdmc+DQo=);
  background-size: 60% 60%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: hsl(7, 5%, 63%);
}

.radio {
  width: 2rem;
  height: 2rem;
  transition: background-color 0.15s;
}
.radio:checked {
  background-image: url(data:image/svg+xml,%3C%3Fxml%20version%3D%221.0%22%20standalone%3D%22no%22%3F%3E%3Csvg%20version%3D%221.1%22%20width%3D%2240%22%20height%3D%2240%22%0AviewBox%3D%220%200%2080%2080%22%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%3E%3Ccircle%20cx%3D%2240%22%20cy%3D%2240%22%20r%3D%2224%22%20style%3D%22fill%3A%23ffffff%22/%3E%3C/svg%3E);
  background-size: 80% 80%;
  background-position: center;
  background-repeat: no-repeat;
  background-color: hsl(7, 5%, 63%);
}

/* ----------------------------- */
/* ==Tags                      */
/* ----------------------------- */
/* use .tag-- classes for variants */
.tag, .tag--success, .tag--danger, .tag--warning, .tag--edit, .tag--search, .tag--highlight, .tag--primary {
  display: inline-block;
  padding: 3px 5px 3px 0;
  vertical-align: baseline;
  white-space: nowrap;
  color: hsl(4, 5%, 38%);
  border-radius: 0 0 0.3rem 0;
  border-style: solid;
  border-width: 0 1px 1px 0;
  line-height: 1;
}

.tag--primary {
  color: hsl(199, 69%, 37%);
}
.tag--highlight {
  color: hsl(357, 100%, 40%);
}
.tag--search {
  color: hsl(41, 100%, 49%);
}
.tag--edit {
  color: hsl(199, 69%, 31%);
}
.tag--warning {
  color: hsl(23, 100%, 45%);
}
.tag--danger {
  color: hsl(349, 100%, 24%);
}
.tag--success {
  color: hsl(125, 100%, 20%);
}

.tag--small {
  font-size: 1.4rem;
}
.tag--big {
  font-size: 2.2rem;
}
.tag--block {
  width: 100% !important;
  display: block;
}
.tag.disabled, .disabled.tag--primary, .disabled.tag--highlight, .disabled.tag--search, .disabled.tag--edit, .disabled.tag--warning, .disabled.tag--danger, .disabled.tag--success, .tag--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.tag:empty, .tag--primary:empty, .tag--highlight:empty, .tag--search:empty, .tag--edit:empty, .tag--warning:empty, .tag--danger:empty, .tag--success:empty {
  display: none;
}

/* ----------------------------- */
/* ==Badges                      */
/* ----------------------------- */
/* use .badge-- classes for variants */
.badge, .badge--success, .badge--danger, .badge--warning, .badge--edit, .badge--search, .badge--highlight, .badge--primary {
  display: inline-block;
  padding: 0.5rem;
  border-radius: 50%;
  color: hsl(4, 5%, 28%);
  background-color: hsl(4, 5%, 90%);
  line-height: 1;
}
.badge::before, .badge--success::before, .badge--danger::before, .badge--warning::before, .badge--edit::before, .badge--search::before, .badge--highlight::before, .badge--primary::before {
  content: "";
  display: inline-block;
  vertical-align: middle;
  padding-top: 100%;
}

.badge--primary {
  background-color: hsl(199, 69%, 31%);
  color: hsl(199, 69%, 37%);
}
.badge--highlight {
  background-color: hsl(2, 78%, 51%);
  color: hsl(357, 100%, 40%);
}
.badge--search {
  background-color: hsl(46, 100%, 50%);
  color: hsl(41, 100%, 49%);
}
.badge--edit {
  background-color: hsl(199, 70%, 83%);
  color: hsl(199, 69%, 31%);
}
.badge--warning {
  background-color: hsl(30, 100%, 50%);
  color: hsl(23, 100%, 45%);
}
.badge--danger {
  background-color: hsl(353, 100%, 32%);
  color: hsl(349, 100%, 24%);
}
.badge--success {
  background-color: hsl(125, 62%, 33%);
  color: hsl(125, 100%, 20%);
}

.badge--small {
  font-size: 1.4rem;
}
.badge--big {
  font-size: 2.2rem;
}
.badge.disabled, .disabled.badge--primary, .disabled.badge--highlight, .disabled.badge--search, .disabled.badge--edit, .disabled.badge--warning, .disabled.badge--danger, .disabled.badge--success, .badge--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.badge:empty, .badge--primary:empty, .badge--highlight:empty, .badge--search:empty, .badge--edit:empty, .badge--warning:empty, .badge--danger:empty, .badge--success:empty {
  display: none;
}

/* ----------------------------- */
/* ==Alerts                      */
/* ----------------------------- */
/* use .alert-- classes for variants */
.alert, .info--primary, .alert--success, .alert--warning, .alert--primary {
  padding: 2rem 4rem;
  margin-top: 0;
  margin-bottom: 0;
  color: hsl(4, 5%, 28%);
  border-radius: 0.3rem;
  background-color: hsl(4, 5%, 90%);
  text-align: justify;
}
.alert .content a, .info--primary .content a, .alert--success .content a, .alert--warning .content a, .alert--primary .content a {
  color: inherit;
}
.alert > .row-link, .info--primary > .row-link, .alert--success > .row-link, .alert--warning > .row-link, .alert--primary > .row-link {
  font-size: 1.44rem;
  text-align: right;
  text-align-last: right;
}
@media (min-width: 576px) {
  .alert > .row-link, .info--primary > .row-link, .alert--success > .row-link, .alert--warning > .row-link, .alert--primary > .row-link {
    font-size: 1.62rem;
  }
}

.alert--primary {
  background-color: hsl(199, 20%, 93%);
  border-left: 3px solid hsl(199, 64%, 59%);
  color: hsl(199, 72%, 24%);
  box-shadow: 0 1px 3px hsla(4, 10%, 5%, 0.12), 0 1px 3px hsla(4, 10%, 5%, 0.24);
}
.alert--warning {
  background-color: hsl(20, 80%, 93%);
  border-left: 3px solid hsl(30, 100%, 50%);
  color: hsl(23, 100%, 45%);
  box-shadow: 0 1px 3px hsla(4, 10%, 5%, 0.12), 0 1px 3px hsla(4, 10%, 5%, 0.24);
}
.alert--success {
  background-color: hsl(125, 20%, 92%);
  border-left: 3px solid hsl(125, 48%, 55%);
  color: hsl(125, 62%, 33%);
  box-shadow: 0 1px 3px hsla(4, 10%, 5%, 0.12), 0 1px 3px hsla(4, 10%, 5%, 0.24);
}

.info--primary {
  background-color: hsl(199, 20%, 93%);
  border: 1px solid hsl(199, 64%, 59%);
  color: hsl(199, 72%, 24%);
}
.info--primary.info--fit {
  width: fit-content;
}

.alert--small {
  font-size: 1.4rem;
}
.alert--big {
  font-size: 2.2rem;
}
.alert--block {
  width: 100% !important;
  display: block;
}
.alert.disabled, .disabled.alert--primary, .disabled.alert--warning, .disabled.alert--success, .disabled.info--primary, .alert--disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.alert:empty, .alert--primary:empty, .alert--warning:empty, .alert--success:empty, .info--primary:empty {
  display: none;
}

.autocomplete {
  position: relative;
}

.autocomplete-result-list {
  background-color: #fafafa;
  border: solid 1px #d9d9d9;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  visibility: hidden;
  z-index: 1;
}

.autocomplete-result-list > div > * {
  padding: 0.5rem;
}

.autocomplete-result-list > div > a {
  display: block;
  color: hsl(4, 5%, 28%);
}

.autocomplete-result-list > div:hover, .autocomplete-result-list > div.hover {
  background-color: #d9d9d9;
}

.autocomplete-input:focus + .autocomplete-result-list:not(:empty), .autocomplete[autocomplete-show-results=true] .autocomplete-result-list:hover {
  visibility: visible;
}

.banner {
  background-color: #fafafa;
  border-radius: 0.7rem;
  color: hsl(4, 5%, 38%);
  display: block;
  padding: 3rem;
  max-width: 650px;
  margin: auto;
  text-align: center;
}
.banner > .logo-txt-logo {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.banner > .logo-txt-logo > div:nth-child(2) {
  margin-top: 2rem;
}
.banner img {
  height: 200px;
  width: 200px;
}

.banner .ltl-action {
  color: hsl(5, 5%, 50%);
}

.block.block--color {
  margin: 12rem 0 0;
  font-weight: 450;
  color: #fafafa;
}
.block.block--color + .block {
  margin-top: 8rem;
}
.block.block--color > .sub-block--color {
  padding: 8rem 0;
}
.block.block--color.block--color--nearby {
  margin: 6rem 0 0;
}
.block.block--color.block--color--nearby > .sub-block--color {
  padding: 6rem 0;
}
.block.block--color.block--color--event-list {
  margin: 2rem 0;
}
.block.block--color.block--color--event-list > .sub-block--color {
  padding: 2rem 0.5rem;
}
@media (min-width: 400px) {
  .block.block--color.block--color--event-list > .sub-block--color {
    padding: 2rem 2rem;
  }
}
.block.block--color h1 {
  margin-bottom: 4rem;
}
.block.block--color h2 {
  margin-bottom: 2rem;
}
.block.block--color::before {
  mask: url("../../images/border-top.svg") repeat-x;
  content: "";
  display: block;
  height: 40px;
  mask-size: 1450px;
}
.block.block--color::after {
  mask: url("../../images/border-bottom.svg") repeat-x;
  content: "";
  display: block;
  height: 40px;
  mask-size: 1450px;
}
.block.block--color.block--color-primary::before, .block.block--color.block--color-primary > .sub-block--color, .block.block--color.block--color-primary::after {
  background-color: hsl(199, 90%, 12%);
}
.block.block--color.block--color-green::before, .block.block--color.block--color-green > .sub-block--color, .block.block--color.block--color-green::after {
  background-color: hsl(125, 90%, 9%);
}
.block.block--color.block--color-black::before, .block.block--color.block--color-black > .sub-block--color, .block.block--color.block--color-black::after {
  background-color: hsl(4, 5%, 5%);
}

.share-container > .share-links > *, .icon-background, .btn--menu-icon, .btn--flash, .btn--footer-plus, .btn--round, .tag--round {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn--flash, .btn--footer-plus, .btn--round, .tag--round {
  border-radius: 50%;
}

.btn--flash, .btn--footer-plus, .btn--round {
  cursor: pointer;
}

.btn--footer-plus {
  font-size: 2rem;
  height: 6rem;
  width: 6rem;
  margin: 1rem 0;
}
@media (min-width: 576px) {
  .btn--footer-plus {
    font-size: 2.4rem;
  }
}

.btn--flash {
  font-size: 2rem;
  right: 2rem;
  bottom: 2rem;
  height: 7rem;
  width: 7rem;
}
@media (min-width: 576px) {
  .btn--flash {
    font-size: 2.4rem;
  }
}

button > .faws + span, .btn > .faws + span {
  margin-left: 0.5rem;
}

.btn--big {
  font-size: 2.08rem;
  border-radius: 0.7rem;
  margin: 0 auto;
  padding: 2rem;
}
@media (min-width: 576px) {
  .btn--big {
    font-size: 2.52rem;
  }
}

.btn--menu-icon {
  min-height: 5rem;
  min-width: 5rem;
  cursor: pointer;
}

.btn--background, .btn--login {
  background-color: #f8f6f6;
}

.btn--login {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 4rem;
}
.btn--login > :first-child {
  justify-content: center;
  width: 20%;
}

span.code-block {
  background-color: hsl(2, 78%, 51%);
  color: #fafafa;
  padding: 0 2rem;
  display: inline-block;
  border-radius: 3px;
  box-shadow: 0px 0px 1px hsl(2, 78%, 51%);
}

.code-promo {
  background-color: #fafafa;
  border: 2px solid #dedede;
  border-radius: 0.7rem;
  padding: 2rem;
}
.code-promo .code-validity {
  color: hsl(7, 5%, 63%);
  font-size: 1.44rem;
}
@media (min-width: 576px) {
  .code-promo .code-validity {
    font-size: 1.62rem;
  }
}
.code-promo .code-name {
  color: hsl(357, 100%, 40%);
}

.cta-btn-right {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  align-items: center;
  text-align: center;
}
.cta-btn-right > .cta-btn-right--buttons {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 1rem;
}
.cta-btn-right > .cta-btn-right--action {
  text-align: center;
}

.cta-big-one {
  color: hsl(4, 5%, 28%);
  border-radius: 1.5rem;
  background-color: #ffffff;
  padding: 3rem 6rem;
  text-decoration: none;
}
.cta-big-one .cta-big-one--desc {
  font-size: 1.44rem;
  color: hsl(4, 5%, 38%);
  padding: 1rem 0;
}
@media (min-width: 576px) {
  .cta-big-one .cta-big-one--desc {
    font-size: 1.62rem;
  }
}
.cta-big-one .cta-big-one--title {
  font-family: Karla, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 2.08rem;
}
@media (min-width: 576px) {
  .cta-big-one .cta-big-one--title {
    font-size: 2.52rem;
  }
}
.cta-big-one .cta-big-one--button {
  font-size: 1.76rem;
  text-align: center;
  padding: 0.5rem 2rem;
  border-radius: 3px;
  transition: background-color 500ms;
}
@media (min-width: 576px) {
  .cta-big-one .cta-big-one--button {
    font-size: 2.16rem;
  }
}
.cta-big-one .cta-big-one--button--light {
  color: hsl(357, 100%, 40%);
  text-align: center;
}
.cta-big-one .cta-big-one--mention {
  color: hsl(7, 5%, 63%);
  font-size: 1.44rem;
  text-align: center;
  height: 2em;
  margin-top: 0.5rem;
}
@media (min-width: 576px) {
  .cta-big-one .cta-big-one--mention {
    font-size: 1.62rem;
  }
}
@media (max-width: 767px) {
  .cta-big-one .cta-big-one--mention--ghost {
    display: none;
  }
}
.cta-big-one:hover {
  color: hsl(4, 5%, 28%);
}
.cta-big-one:hover div.cta-big-one--button {
  background-color: hsl(357, 100%, 40%);
}

.carousel-words {
  display: flex;
}
.carousel-words > .carousel-placeholder {
  display: none; /* shown in html to have something waiting for css load and animation */
}
.carousel-words > .carousel-words-list {
  display: block !important; /* hidden in html to avoid complete list show */
  height: 1.5em;
  line-height: 1.5em;
  overflow: hidden;
}
.carousel-words > .carousel-words-list > div {
  position: relative;
  overflow: hidden;
}
.carousel-words--scroll-10 > .carousel-words-list > div {
  animation: scroll-10 15s ease-in-out infinite;
}
.carousel-words--scroll-underlined-10 > .carousel-words-list {
  border-bottom: 1px solid;
}
.carousel-words--scroll-underlined-10 > .carousel-words-list > div {
  animation: scroll-10 15s ease-in-out infinite;
}
.carousel-words--highlight > .carousel-words-list > div {
  background-color: hsl(2, 78%, 51%);
  color: #fff;
  animation: openclose 5s ease-in-out infinite;
}
.carousel-words--highlight > .carousel-words-list > div > div {
  padding: 0 1rem;
}

@keyframes scroll-10 {
  0% {
    top: 0rem;
  }
  5% {
    top: 0rem;
  }
  10% {
    top: -1.5em;
  }
  15% {
    top: -1.5em;
  }
  20% {
    top: -3em;
  }
  25% {
    top: -3em;
  }
  30% {
    top: -4.5em;
  }
  35% {
    top: -4.5em;
  }
  40% {
    top: -6em;
  }
  45% {
    top: -6em;
  }
  50% {
    top: -7.5em;
  }
  55% {
    top: -7.5em;
  }
  60% {
    top: -9em;
  }
  65% {
    top: -9em;
  }
  70% {
    top: -10.5em;
  }
  75% {
    top: -10.5em;
  }
  80% {
    top: -12em;
  }
  85% {
    top: -12em;
  }
  90% {
    top: -13.5em;
  }
  95% {
    top: -13.5em;
  }
  100% {
    top: -15em;
  }
}
@keyframes openclose {
  0% {
    top: 0rem;
    width: 0;
  }
  5% {
    width: 0;
  }
  15% {
    width: 100%;
  }
  30% {
    width: 100%;
  }
  33% {
    width: 0;
  }
  35% {
    top: 0rem;
    width: 0;
  }
  38% {
    top: -1.5em;
    width: 0;
  }
  48% {
    width: 100%;
  }
  62% {
    width: 100%;
  }
  66% {
    top: -1.5em;
    width: 0;
  }
  71% {
    top: -3em;
    width: 0;
  }
  86% {
    width: 100%;
  }
  95% {
    width: 100%;
  }
  98% {
    top: -3em;
    width: 0;
  }
  100% {
    top: 0;
    width: 0;
  }
}
a.event-result {
  color: hsl(4, 5%, 28%);
}
a.event-result:hover {
  border: 2px solid #dedede;
  border-color: hsl(199, 62%, 44%);
}
a.event-result:hover .event-name, a.event-result:hover .event-desc {
  color: hsl(199, 62%, 44%);
}

.event-result .event-name {
  color: hsl(4, 5%, 28%);
  font-family: Karla, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.92rem;
  margin-bottom: 0.7rem;
}
@media (min-width: 576px) {
  .event-result .event-name {
    font-size: 2.34rem;
  }
}
.event-result .event-desc {
  font-family: Karla, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
}

.event-result.event-result-small .event-name {
  font-family: Karla, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 1.6rem;
  max-height: 3em;
  margin-bottom: 0.7rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
@media (min-width: 576px) {
  .event-result.event-result-small .event-name {
    font-size: 1.8rem;
  }
}
.event-result.event-result-link {
  display: flex;
  justify-content: center;
  align-items: center;
}
.event-result.event-result-link .event-name {
  font-size: 1.76rem;
  font-weight: 400;
}
@media (min-width: 576px) {
  .event-result.event-result-link .event-name {
    font-size: 1.98rem;
  }
}

.image-scale {
  display: block;
  height: 100%;
}

.icon-background {
  font-size: 3rem;
  height: 100%;
  border-radius: 0.7rem;
  border: 2px solid hsl(4, 5%, 90%);
}

.event-image-big {
  display: none;
}

.event-image-small {
  font-size: 1.76rem;
  text-align: center;
  height: 100px;
  width: 100px;
}
@media (min-width: 576px) {
  .event-image-small {
    font-size: 2.16rem;
  }
}
.event-image-small .image-scale {
  box-shadow: 0 1px 3px hsla(4, 10%, 5%, 0.12), 0 1px 3px hsla(4, 10%, 5%, 0.24);
  border-radius: 0.7rem;
}

.map-marker--title {
  font-size: 1.6rem;
}
@media (min-width: 576px) {
  .map-marker--title {
    font-size: 1.8rem;
  }
}
.map-marker--title svg {
  margin-right: 1rem;
}

.map-marker--desc {
  font-size: 1.44rem;
}
@media (min-width: 576px) {
  .map-marker--desc {
    font-size: 1.62rem;
  }
}

[readonly] {
  background-color: hsl(4, 5%, 90%);
}

[data-readonly-toggle] {
  cursor: pointer;
}

.form-row:not([show-if]) ~ .form-row {
  margin-top: 2rem !important;
}

.form-row ~ .form-row {
  margin-top: 2rem;
}

.form-row + .row-action {
  margin-top: 4rem;
}

.form-item + .form-item {
  margin-top: 1rem;
}

.switch-container {
  display: flex;
}
.switch-container > :nth-child(1) {
  flex-grow: 1;
}

.choice-colored-container {
  padding: 1rem;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #d5d5d5;
}

form {
  position: relative;
}
form label.required::after, form label.soft-required::after {
  color: red;
  content: " *";
}
form .radio-colored label.required::after {
  content: "";
}

input[type=checkbox], input[type=radio] {
  height: 2.5rem;
  width: 2.5rem;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  margin: 0;
  user-select: none;
}
input[type=checkbox]:checked, input[type=radio]:checked {
  background-color: hsl(199, 69%, 37%);
}
input[type=checkbox]:not(.inverted), input[type=radio]:not(.inverted) {
  margin-right: 1rem;
}
input[type=checkbox].inverted, input[type=radio].inverted {
  margin-left: 1rem;
}
input[type=checkbox] + label, input[type=radio] + label {
  display: inline;
}

input, optgroup, textarea {
  width: 100%;
}

optgroup {
  background-color: hsl(4, 5%, 90%);
}

textarea.textarea--lg {
  min-height: 50rem;
}

select {
  width: 100%;
}

.field-invariable, .field, .field-shape {
  border: 1px solid hsl(4, 5%, 74%);
  border-radius: 0.3rem;
  background-color: #fff;
}

.field-invariable, .field, .field-ghost {
  padding: 0.5rem 1rem;
  user-select: none;
}

.field-invariable {
  background-color: hsl(4, 5%, 90%);
  pointer-events: none;
}

.frame-light {
  border: 1px solid hsl(4, 5%, 74%);
  border-radius: 0.3rem;
  padding: 1rem;
}
.frame-light.frame-light--fill {
  background-color: #fafafa;
}
.frame-light.frame-light--fill-egg {
  background-color: #f8f6f6;
}

.frame-heavy {
  border: 2px solid hsl(199, 69%, 37%);
  padding: 1rem;
  border-radius: 0.7rem;
}

.frame-medium, .markdown-parse .link-list-highlight {
  border: 1px solid hsl(199, 69%, 37%);
  padding: 1rem;
  border-radius: 0.7rem;
}

@media (min-width: 400px) {
  .frame-light {
    padding: 2rem;
  }
  .frame-medium, .markdown-parse .link-list-highlight {
    padding: 2rem;
  }
  .frame-heavy {
    padding: 2rem;
  }
}
@media (min-width: 768px) {
  .form-row.form-row--level-2 {
    padding: 2rem;
  }
  .frame-medium, .markdown-parse .link-list-highlight {
    padding: 4rem;
  }
  .frame-heavy {
    padding: 4rem;
  }
}
.loading::after {
  content: "";
  position: absolute;
  bottom: -2rem;
  left: -2rem;
  right: -2rem;
  top: -2rem;
  background-color: rgba(238, 238, 238, 0.6);
  z-index: 6000;
  background-image: url("../../images/spinner.svg");
  background-size: 100px;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 1rem;
}

body.loading::after {
  position: fixed;
}

.markdown-parse p {
  margin-bottom: 2rem;
  text-align: justify;
  text-justify: inter-word;
}
.markdown-parse .link-alone {
  margin: 8rem 0;
  text-align: center;
}
.markdown-parse .link-list-highlight {
  font-weight: 600;
  margin: 8rem 0;
}
.markdown-parse .link-list-highlight ul {
  margin: 0;
  padding: 0;
}
.markdown-parse .link-list-highlight ul li {
  padding-left: 1rem;
  list-style: circle;
}

[menu-shrink] {
  position: relative;
  background: #fafafa;
  box-shadow: 0 1px 3px hsla(4, 10%, 5%, 0.12), 0 1px 3px hsla(4, 10%, 5%, 0.24);
  border-radius: 0.3rem;
  align-items: stretch;
}
[menu-shrink]:not([menu-shrink-empty]) [menu-shrink-trigger=global] {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  cursor: pointer;
}
[menu-shrink][menu-shrink-empty] .menu-shrink--button {
  display: none;
}
[menu-shrink][menu-shrink-open=true] [menu-shrink-show-if=closed] {
  display: none;
}
[menu-shrink][menu-shrink-open=true] [menu-shrink-item=current], [menu-shrink][menu-shrink-open=true] [menu-shrink-item=item] {
  display: flex;
}
[menu-shrink][menu-shrink-open=true] [menu-shrink-item=ul] ul > ul {
  display: inherit;
}

.pagination .btn--primary--border-light {
  background-color: #f3f2f2;
}

@media (max-width: 575px) {
  /* quick small resolution reset */
  .flex-container {
    flex-direction: column;
  }
  .flex-row {
    flex-direction: row;
  }
  /* layouts for tiny screens */
  .tiny-hidden {
    display: none !important;
  }
  .tiny-visible {
    display: block !important;
  }
}
.search {
  font-size: 1.44rem;
  padding: 4rem 1rem 1rem;
}
@media (min-width: 576px) {
  .search {
    font-size: 1.62rem;
  }
}
.search .search-label {
  font-size: 1.76rem;
  margin-bottom: 2rem;
}
@media (min-width: 576px) {
  .search .search-label {
    font-size: 2.16rem;
  }
}
.search .search-grid {
  display: grid;
  grid-gap: 2rem;
  align-items: center;
}
.search .search-grid .search-line {
  display: grid;
  grid-auto-flow: row;
  grid-gap: 2rem;
}
.search .search-grid .search-datepicker {
  display: flex;
  justify-content: center;
  box-shadow: 0 0 0 1px #c3b7b6 inset;
  background-color: #fff;
  border-radius: 0.3rem;
  height: 4rem;
  width: 4rem;
  margin-left: 1rem;
  align-items: center;
}
.search .search-grid .search-datepicker:hover {
  background-color: #fafafa;
}
.search .search-grid .search-submit button {
  border-radius: 0.3rem;
  height: 4rem;
  width: 100%;
}
.search .search-grid input[type=text], .search .search-grid select {
  height: 4rem;
}
.search .search-grid input[type=text]:focus, .search .search-grid select:focus {
  box-shadow: 1px 1px 3px 0 #fec501;
}

[name=event_search] [type=text], [name=event_search] select, [name=event_search] [type=submit] {
  margin: 0;
}

.shadow-1 {
  box-shadow: 0 1px 3px hsla(4, 10%, 5%, 0.12), 0 1px 3px hsla(4, 10%, 5%, 0.24);
}
.shadow-1.shadow-action {
  transition-property: box-shadow;
  transition-duration: 0.3s;
}
.shadow-1.shadow-action:focus, .shadow-1.shadow-action:active {
  box-shadow: none;
}
.shadow-1.shadow-action:hover {
  box-shadow: 0 10px 20px hsla(4, 10%, 5%, 0.15), 0 3px 6px hsla(4, 10%, 5%, 0.1);
}

.shadow-2 {
  box-shadow: 0 3px 6px hsla(4, 10%, 5%, 0.15), 0 2px 4px hsla(4, 10%, 5%, 0.12);
}

.shadow-3 {
  box-shadow: 0 10px 20px hsla(4, 10%, 5%, 0.15), 0 3px 6px hsla(4, 10%, 5%, 0.1);
}

.shadow-4 {
  box-shadow: 0 15px 25px hsla(4, 10%, 5%, 0.15), 0 5px 10px hsla(4, 10%, 5%, 0.05);
}

.shadow-5 {
  box-shadow: 0 20px 40px hsla(4, 10%, 5%, 0.2);
}

/* The fast, new way! */
.cta-big-one {
  position: relative; /* For positioning the pseudo-element */
  box-shadow: 0 3px 6px hsla(4, 10%, 5%, 0.15), 0 2px 4px hsla(4, 10%, 5%, 0.12);
}

.cta-big-one::before {
  /* Position the pseudo-element. */
  border-radius: inherit;
  content: " ";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  /* Create the box shadow at expanded size. */
  box-shadow: 0 10px 20px hsla(4, 10%, 5%, 0.15), 0 3px 6px hsla(4, 10%, 5%, 0.1);
  /* Hidden by default. */
  opacity: 0;
  transition: opacity 500ms;
}

.cta-big-one:hover::before {
  /* Show the pseudo-element on hover. */
  opacity: 1;
}

.share-page {
  z-index: 1001;
}

.share-container > .share-links {
  padding: 1rem;
  background: #f8f6f6;
  box-shadow: 0 3px 6px hsla(4, 10%, 5%, 0.15), 0 2px 4px hsla(4, 10%, 5%, 0.12);
  border-radius: 0.7rem;
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 5rem;
  grid-column-gap: 1rem;
  width: fit-content;
}
.share-container > .share-links > * {
  border-radius: 0.3rem;
  height: 5rem;
  width: 5rem;
  box-shadow: 0 1px 3px hsla(4, 10%, 5%, 0.12), 0 1px 3px hsla(4, 10%, 5%, 0.24);
  color: #fafafa;
  font-size: 2rem;
}
@media (min-width: 576px) {
  .share-container > .share-links > * {
    font-size: 2.4rem;
  }
}
.share-container > .share-links > *:hover {
  color: #f8f6f6;
}
.share-container > .share-links > * svg {
  vertical-align: top;
}
.share-container > .share-links > .share-default {
  cursor: pointer;
  background: hsl(125, 62%, 33%);
}
.share-container > .share-links > .share-facebook {
  background: hsl(221, 44%, 41%);
}
.share-container > .share-links > .share-reddit {
  background: #ff1a00;
}
.share-container > .share-links > .share-twitter {
  background: #2daae1;
}
.share-container > .share-links > .share-linkedin {
  background: #0073b2;
}
.share-container > .share-links > .share-google {
  background: hsl(7, 71%, 55%);
}

.show-margin[show-status=on] .show-margin-item + .show-margin-item {
  margin-top: 2rem;
}

[show-auto-scroll=container] {
  overflow: auto;
}