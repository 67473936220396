[menu-shrink] {
    position: relative;

    &:not([menu-shrink-empty]) {
        [menu-shrink-trigger="global"] {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            cursor: pointer;
        }
    }

    &[menu-shrink-empty] {
        .menu-shrink--button {
            display: none;
        }
    }

    background: $white;
    box-shadow: $shadow-1;
    border-radius: $border-radius-1;

    align-items: stretch;

    &[menu-shrink-open="true"] {
        [menu-shrink-show-if="closed"] {
            display: none;
        }

        [menu-shrink-item="current"], [menu-shrink-item="item"] {
            display: flex;
        }

        [menu-shrink-item="ul"] ul > ul {
            display: inherit;
        }
    }
}
