.markdown-parse {
    p {
        margin-bottom: $spacer-medium;
        text-align: justify;
        text-justify: inter-word;
    }

    .link-alone {
        margin: $spacer-extra-large 0;
        text-align: center;
    }

    .link-list-highlight {
        @extend .frame-medium;
        font-weight: $weight-bold;
        margin: $spacer-extra-large 0;

        ul {
            margin: 0;
            padding: 0;

            li {
                padding-left: $spacer-small;
                list-style: circle;
            }
        }
    }
}