/* Links */
a, .llink {
    color: $link-color;
    text-decoration: $link-decoration;

    &:hover {
        cursor: pointer;
    }

    &:focus,
    &:hover,
    &:active {
        color: $link-color-hover;
        text-decoration: $link-decoration-hover;
    }

    &.llink--white {
        color: $white-flash;

        &:focus,
        &:hover,
        &:active {
            color: $white-flash;
        }
    }
}

.color, .llink {
    @each $name, $color, $background-color, $background-hover, $background-ghost, $color-white, $color-inverse, $border-lighter in $variants-list {
        &--#{$name} {
            color: $color;

            &:focus,
            &:hover,
            &:active {
                color: $color;
            }

            &.inverse-colors {
                color: $color-inverse;

                &:focus,
                &:hover,
                &:active {
                    color: $color-inverse;
                }
            }
        }
    }
}

#web-footer {
    a {
        text-decoration: none;
    }
}

p,
address,
ol,
ul,
dl,
blockquote,
pre {
    margin-top: 0;
    margin-bottom: $paragraph-margin-bottom;
}

/* Avoid margins on nested elements */
li p,
li .p-like,
li ul,
li ol,
ol ol,
ul ul {
    margin-top: 0;
    margin-bottom: 0;
}

/* Max values */
img,
table,
td,
blockquote,
code,
pre,
textarea,
input,
video,
svg {
    max-width: 100%;
}

img {
    height: auto;
}

/* Styling elements */
ul,
ol {
    padding-left: 2em;
}

img {
    vertical-align: middle;
}

em,
.italic,
address,
cite,
i,
var {
    font-style: italic;
}

code,
kbd,
mark {
    border-radius: 2px;
}

kbd {
    padding: 0 2px;
    border: 1px solid #999;
}

pre {
    tab-size: 2;
}

code {
    padding: 2px 4px;
    background: rgba(0, 0, 0, 0.04);
    color: #b11;
}

pre code {
    padding: 0;
    background: none;
    color: inherit;
    border-radius: 0;
}

mark {
    padding: 2px 4px;
}

sup,
sub {
    vertical-align: 0;
}

sup {
    bottom: 1ex;
}

sub {
    top: 0.5ex;
}

blockquote {
    position: relative;
    padding-left: 3em;
    padding-right: 3em;
    min-height: 2em;
}

blockquote::before {
    content: "\201C";
    position: absolute;
    left: 0;
    top: 0;
    font-family: georgia, serif;
    font-size: 5em;
    height: .4em;
    line-height: .9;
    color: $quote-color;
}

blockquote > footer {
    margin-top: .75em;
    font-size: 0.9em;
    color: rgba(0, 0, 0, .7);

    &::before {
        content: "\2014 \0020";
    }
}

q {
    font-style: normal;
}

q,
.q {
    quotes: "“" "”" "‘" "’";

    &:lang(fr) {
        quotes: "«\00a0" "\00a0»" "“" "”";
    }
}

hr {
    display: block;
    clear: both;
    height: 1px;
    margin: 1em 0 2em;
    padding: 0;
    border: 0;
    color: #ccc;
    background-color: #ccc;
}

blockquote,
figure {
    margin-left: 0;
    margin-right: 0;
}

code,
pre,
samp,
kbd {
    white-space: pre-wrap;
    font-family: $font-family-monospace;
    line-height: normal;
}