.shadow-1 {
    box-shadow: $shadow-1;

    &.shadow-action {
        transition-property: box-shadow;
        transition-duration: 0.3s;
        &:focus,
        &:active {
            box-shadow: none;
        }
        &:hover {
            box-shadow: $shadow-3;
        }
    }
}

.shadow-2 {
    box-shadow: $shadow-2;
}

.shadow-3 {
    box-shadow: $shadow-3;
}

.shadow-4 {
    box-shadow: $shadow-4;
}

.shadow-5 {
    box-shadow: $shadow-5;
}

/* The fast, new way! */
%shadow-hover {
    position: relative; /* For positioning the pseudo-element */
    box-shadow: $shadow-2;
}

%shadow-hover::before {
    /* Position the pseudo-element. */
    border-radius: inherit;
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    /* Create the box shadow at expanded size. */
    box-shadow: $shadow-3;

    /* Hidden by default. */
    opacity: 0;
    transition: opacity 500ms;
}

%shadow-hover:hover::before {
    /* Show the pseudo-element on hover. */
    opacity: 1;
}

.cta-big-one {
    @extend %shadow-hover;
}