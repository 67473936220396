[readonly] {
    background-color: $color-muted;
    // pointer-events: none; WARNING datepicker !!!
}

[data-readonly-toggle] {
    cursor: pointer;
}

.form-row:not([show-if]) ~ .form-row {
    margin-top: $spacer-medium!important;
}

.form-row ~ .form-row {
    margin-top: $spacer-medium;
}

.form-row + .row-action {
    margin-top: $spacer-large;
}

.form-item + .form-item {
    margin-top: $spacer-small;
}

.switch-container {
    display: flex;

    > :nth-child(1) {
        flex-grow: 1;
    }
}

.choice-colored-container {
    padding: $spacer-small;
    border-width: 0 1px 1px 1px;
    border-style: solid;
    border-color: #d5d5d5;
}

form {
    position: relative;

    label.required::after, label.soft-required::after {
        color: red;
        content: ' *';
    }

    .radio-colored {
        label.required::after {
            content: '';
        }
    }
}

input[type="checkbox"], input[type="radio"] {
    height: 2.5rem;
    width: 2.5rem;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    margin: 0;
    user-select: none;

    &:checked {
        background-color: $color-primary;
    }

    &:not(.inverted) {
        margin-right: $spacer-small;
    }
    &.inverted {
        margin-left: $spacer-small;
    }

    + label {
        display: inline;
    }
}

input, optgroup, textarea {
    width: 100%;
}

optgroup {
    background-color: $color-muted;
}

textarea.textarea--lg {
    min-height: 50rem;
}

select {
    width: 100%;
}

%field-shape {
    border: $border-width-1 solid $form-color;
    border-radius: $border-radius-1;
    background-color: #fff;
}

.field-shape {
    @extend %field-shape;
}

%field {
    padding: $padding-field;
    user-select: none;
}

.field-ghost {
    @extend %field;
}

.field {
    @extend %field;
    @extend %field-shape;
}

.field-invariable {
    @extend %field;
    @extend %field-shape;
    background-color: $color-muted;
    pointer-events: none;
}

.frame-light {
    border: $border-width-1 solid $form-color;
    border-radius: $border-radius-1;
    padding: $spacer-small;

    &.frame-light--fill {
        background-color: $white;
    }

    &.frame-light--fill-egg {
        background-color: $egg-shell;
    }
}

.frame-heavy {
    border: $border-width-2 solid $primary-500;
    padding: $spacer-small;
    border-radius: $border-radius-2;
}

.frame-medium {
    border: $border-width-1 solid $primary-500;
    padding: $spacer-small;
    border-radius: $border-radius-2;
}

@media (min-width: $tiny) {
    .frame-light {
        padding: $spacer-medium;
    }

    .frame-medium {
        padding: $spacer-medium;
    }

    .frame-heavy {
        padding: $spacer-medium;
    }
}

@media (min-width: $medium) {
    .form-row {
        &.form-row--level-2 {
            padding: $spacer-medium;
        }
    }

    .frame-medium {
        padding: $spacer-large;
    }

    .frame-heavy {
        padding: $spacer-large;
    }
}